import React from 'react';
import PropTypes from 'prop-types';

import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';
import { renderTrail } from 'v2/modules/withRunForm/components/Intents/IntentsView/StopConfigView';
import ConfigDisplayDetails from './ConfigDisplayDetails';

const propTypes = {
  stopLoss: PropTypes.shape({}),
  stopGain: PropTypes.shape({}),
  dayStopGain: PropTypes.shape({}),
  dayStopLoss: PropTypes.shape({}),
};

const defaultProps = {
  stopLoss: {},
  stopGain: {},
  dayStopGain: {},
  dayStopLoss: {},
};

const stopValidation = (type, value) => {
  if (_.isUndefined(type) || _.isUndefined(value)) return undefined;
  if (type === STOPS_VALUE_TYPES.Percent) return `${value}%`;
  if (type === STOPS_VALUE_TYPES.Amount) return `${value}₹`;

  return undefined;
};

const ViewConfigStopValues = ({
  stopLoss: propsStopLoss,
  stopGain: propsStopGain,
  dayStopGain: propsDayStopGain,
  dayStopLoss: propsDayStopLoss,
}) => {
  const stopGain = stopValidation(propsStopGain?.type, propsStopGain?.value);
  const stopLoss = stopValidation(propsStopLoss?.type, propsStopLoss?.value);
  const dayStopGain = stopValidation(propsDayStopGain?.type, propsDayStopGain?.value);
  const dayStopLoss = stopValidation(propsDayStopLoss?.type, propsDayStopLoss?.value);

  if (_.isUndefined(stopGain)
      && _.isUndefined(stopLoss)
      && _.isUndefined(dayStopGain)
      && _.isUndefined(dayStopLoss)
  ) return null;

  return (
    <div className="mt-2">
      <div className="card-header">
        <h5 className="m-0">Profit / Order</h5>
      </div>
      <div className="card-body">
        <div className="instrument-groups-input">
          <ConfigDisplayDetails title="Take Profit" value={stopGain} />
          <ConfigDisplayDetails title="Stop Loss">
            {stopLoss}
            &nbsp;
            {renderTrail(propsStopLoss?.uiConfigs?.trail, propsStopLoss)}
          </ConfigDisplayDetails>
          <ConfigDisplayDetails title="Daily Take Profit" value={dayStopGain} />
          <ConfigDisplayDetails title="Daily Stop Loss" value={dayStopLoss} />
        </div>
      </div>
    </div>
  );
};

ViewConfigStopValues.propTypes = propTypes;
ViewConfigStopValues.defaultProps = defaultProps;

export default ViewConfigStopValues;
