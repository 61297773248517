import React from 'react';
import { BROKERS } from 'common/constants';

const propTypes = {};
const defaultProps = {};

const DhanLogin = () => {
  const oneIndentation = ' ';

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <div className="broker mb-3">
            <img
              src={BROKERS.dhan.image}
              alt={BROKERS.dhan.name}
              title={BROKERS.dhan.name}
              className="bigul-login-logo"
            />
          </div>
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Dhan.
          </p>

          <a href="/auth/dhan" className="btn btn-outline-success broker">
            <div className="d-flex align-items-center gap-10">
              <span>Login via Dhan</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

DhanLogin.propTypes = propTypes;
DhanLogin.defaultProps = defaultProps;

export default DhanLogin;
