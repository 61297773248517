import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { planPropTypes } from 'common/propTypes';
import { applyCredits } from 'ui/Plans/helper';
import { initiateUserAuth } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { paymentInitialize } from 'common/razorpay/paymentInitialize';
import PaymentMoneyDisplay from '../common/PaymentMoneyDisplay';
import MultiplierModal from '../common/MultiplierModal';

const propTypes = {
  addon: planPropTypes.isRequired,
  purchaseType: PropTypes.string,
  couponConfig: PropTypes.shape({
    code: PropTypes.string
  }),
  credits: PropTypes.number.isRequired
};

const defaultProps = {
  purchaseType: 'addon',
  couponConfig: {}
};

const AddonCard = ({
  addon, purchaseType, couponConfig, credits
}) => {
  const {
    id, price, addon_name: addonName, currentNetPrice, strike_price: strikePrice, live_run_limit: liveRunLimit
  } = addon;
  const [isUserSignedIn, setIsUserSignedIn] = useState(window.isUserSignedIn);
  const isPlanActive = _.get(window, 'SA_USER_FLAGS.isPlanActive', false);

  const { code: coupenCode } = couponConfig;

  const {
    historic_run_limit: historicLimit,
    live_run_limit: liveLimit,
    cycle_duration_in_months: cycleMonth
  } = addon;
  const historicLimitStatement = `${historicLimit} Backtest credits`;
  const liveLimitStatement = `${liveLimit} live credits`;
  const { hasPaymentEnabled } = window;
  const paramCouponCode = currentNetPrice === price ? '' : coupenCode;
  const isPositiveCreditBalance = _.isNumber(credits) && credits > 0;

  const [multiplier, setMultiplier] = useState(1);
  const [onShowMultiplierModal, setOnShowMultiplierModal] = useState(false);

  const onCreatePayment = () => {
    const args = {
      addon_id: id,
      purchase_type: purchaseType,
      coupon_code: paramCouponCode,
      credits_applied: isPositiveCreditBalance,
      multiplier
    };
    paymentInitialize(args, () => {
      setTimeout(() => { window.location.reload(); }, 1.5 * 1000);
    });
  };

  const onSubmit = () => {
    const isLiveAddon = liveRunLimit > 0;

    if (!isUserSignedIn) {
      return initiateUserAuth()
        .then(() => {
          setIsUserSignedIn(true);
          window.location.reload();
        })
        .catch(() => {
          reactToastify('Unauthorized, Please login and Continue', TOASTIFY_TYPES.ERROR);
        });
    }

    if (isLiveAddon && !isPlanActive) {
      reactToastify('To purchase live addon, you need an active plan', TOASTIFY_TYPES.ERROR);
      return null;
    }

    return setOnShowMultiplierModal(true);
  };

  const renderLimit = (limit, statement) => {
    if (!Number(limit)) return null;

    return <>{statement}</>;
  };

  return (
    <div className="addon-box">
      <h6>{addonName}</h6>
      <PaymentMoneyDisplay
        price={price}
        currentNetPrice={applyCredits(currentNetPrice, credits)}
        strikePrice={strikePrice}
        shouldHideBillCycle
      />
      <p>Excluding GST</p>
      <h6>
        {renderLimit(historicLimit, historicLimitStatement)}
        {renderLimit(liveLimit, liveLimitStatement)}
      </h6>
      <button
        type="button"
        onClick={onSubmit}
        disabled={!hasPaymentEnabled}
      >
        <a href="#submit">
          Add Now
          <i className="fa-solid fa-circle-arrow-right" />
        </a>
      </button>
      {onShowMultiplierModal && (
      <MultiplierModal
        price={currentNetPrice}
        cycleMonth={cycleMonth}
        historicLimit={historicLimit}
        liveLimit={liveLimit}
        multiplier={multiplier}
        onChangeMultiplier={setMultiplier}
        onCreatePayment={onCreatePayment}
        onCloseModal={() => setOnShowMultiplierModal(false)}
      />
      )}
    </div>
  );
};

AddonCard.propTypes = propTypes;
AddonCard.defaultProps = defaultProps;

export default AddonCard;
