import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { transactionPropTypes } from 'common/propTypes';
import TransactionCollapsed from './TransactionCollapsed';
import TransactionExpanded from './TransactionExpanded';

const propTypes = {
  transaction: transactionPropTypes.isRequired,
  cumulativeProfit: PropTypes.number,
  runId: PropTypes.number.isRequired,
  isNumerousRuns: PropTypes.bool,
  isLive: PropTypes.bool.isRequired,
  orderType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  runStatus: PropTypes.string.isRequired
};

const defaultProps = { cumulativeProfit: null, isNumerousRuns: false };

const Transaction = ({
  transaction, cumulativeProfit, runId, isNumerousRuns, isLive, orderType, granularity, runStatus
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const TransactionComponent = isExpanded ? TransactionExpanded : TransactionCollapsed;
  return (
    // eslint-disable-next-line
    <tbody onClick={() => {
      if (isOpenedModal) return;

      setIsExpanded(!isExpanded);
    }}
    >
      <TransactionComponent
        cumulativeProfit={cumulativeProfit}
        transaction={transaction}
        orderType={orderType}
        runId={runId}
        runStatus={runStatus}
        onHandleModalInParent={() => {
          setIsOpenedModal(!isOpenedModal);
        }}
        isLive={isLive}
        isNumerousRuns={isNumerousRuns}
        granularity={granularity}
      />
    </tbody>
  );
};

Transaction.propTypes = propTypes;
Transaction.defaultProps = defaultProps;
export default Transaction;
