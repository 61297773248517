import React from 'react';

// import { BROKERS } from 'common/constants';

const propTypes = {};
const defaultProps = {};

const AngelOneLogin = () => {
  const oneIndentation = ' ';

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with AngelOne Smart API.
            <br />
            <br />
          </p>
          {/* <a href="/auth/angel_broking" className="btn btn-light broker w-50">
            <img
              src={BROKERS.angel_broking.image_white}
              alt={BROKERS.angel_broking.name}
              className="broker-logo w-100"
            />
          </a> */}
          <a href="/auth/angel_broking" className="btn btn-outline-primary broker">
            Login via
            {oneIndentation}
            <b>AngelOne</b>
          </a>
        </div>
      </div>
    </div>
  );
};

AngelOneLogin.propTypes = propTypes;
AngelOneLogin.defaultProps = defaultProps;

export default AngelOneLogin;
