import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { setPartialBounds } from '../../redux/actions';

const propTypes = {};
const defaultProps = {};

const MaxTransactionsPerDay = () => {
  const [limitTransactionName] = useState(_.uniqueId('limitTransactionName-'));
  const { errors, register } = useFormContext();
  const dispatch = useDispatch();
  const maxTransactionsPerDay = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.bounds.maxTransactionsPerDay')
  );

  const onChangeMaxTransactionsPerDay = (event) => {
    dispatch(setPartialBounds({ maxTransactionsPerDay: Number((event.target.value)) }));
  };

  const validatorConfig = {
    validate: (value) => {
      const typeConversionValue = Number(value);
      const isValidCondition = typeConversionValue >= 1 && typeConversionValue <= 20;
      if (!isValidCondition) return 'Must be between 1 to 20';

      return null;
    }
  };

  const validatorProps = { ref: register(validatorConfig) };

  return (
    <div className="limit-transactions">
      <div className="row">
        <div className="col-md-2">
          <label className="label_style">Max Transactions Per Day</label>
        </div>
        <div className="col-md-10">
          <ErrorMesssage message={_.get(errors, `${limitTransactionName}.message`, '')}>
            <Form.Control
              {...validatorProps}
              type="number"
              min="1"
              size="sm"
              name={limitTransactionName}
              value={!maxTransactionsPerDay ? '' : maxTransactionsPerDay.toString()}
              onChange={onChangeMaxTransactionsPerDay}
            />
          </ErrorMesssage>
        </div>
      </div>
    </div>
  );
};

MaxTransactionsPerDay.propTypes = propTypes;
MaxTransactionsPerDay.defaultProps = defaultProps;

export default MaxTransactionsPerDay;
