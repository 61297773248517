import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Money from 'common/components/MoneyDisplay';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';

const propTypes = {
  instrumentGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  tockGroup: PropTypes.shape({}).isRequired
};
const defaultProps = {};

const LiveProfitCard = ({ instrumentGroup, tockGroup }) => {
  const groupProfit = _.get(tockGroup, ['profitDetail', 'profitPrice']);
  const groupProfitPercent = _.get(tockGroup, ['profitDetail', 'profitPercentage']);
  const groupProfitClassName = classNames(
    'font-weight-bold',
    groupProfit < 0 ? 'text-danger' : 'text-success'
  );
  const profitLossLabel = groupProfit < 0 ? 'Loss' : 'Profit';

  return (
    <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
      <h5 className=""><InstrumentGroupsSentence instrumentGroups={[instrumentGroup]} /></h5>
      <div>
        <p className="sub-head text-right">{profitLossLabel}</p>
        <h5 className={groupProfitClassName}>
          {groupProfit ? <Money>{Math.abs(groupProfit)}</Money> : <span>---</span>}
          &nbsp;
          {groupProfitPercent ? (
            <small>
              (
              {Math.abs(_.round(groupProfitPercent, 2)) || groupProfitPercent}
              %)
            </small>
          ) : <small>(--)</small>}
        </h5>
      </div>
    </div>
  );
};

LiveProfitCard.propTypes = propTypes;
LiveProfitCard.defaultProps = defaultProps;

export default LiveProfitCard;
