import React from 'react';

import { signInProps } from 'ui/LandingPage/config';

const propTypes = {};
const defaultProps = {};

const SignIn = () => {
  const renderFormField = (fieldProps, field) => (
    <div className="form-group" key={field}>
      <label>
        {_.startCase(field)}
        <span className="text-danger">*</span>
      </label>
      <div className="inside-icon">
        <i className="material-icons-outlined icons">{`${fieldProps.icon}`}</i>
        <input
          className="form-control"
          {...fieldProps}
        />
      </div>
    </div>
  );

  return (
    <div className="login">
      <form className="login-form mt-4" method="post" action="/users/sign_in">
        {_.map(signInProps, renderFormField)}

        <div className="d-flex justify-content-between mb-2">
          <div className="custom-control custom-checkbox mr-auto hidden-for-future">
            <input type="checkbox" className="custom-control-input" id="customCheck1" />
            <label className="custom-control-label" htmlFor="customCheck1">Remember Me</label>
          </div>

          <a className="text-primary ml-auto" href="/users/password/new">Forget Password?</a>
        </div>

        <button
          className="btn btn-block btn-primary btn-pill"
          type="submit"
        >
          Sign in
        </button>
      </form>
      <div className="clearfix" />
      <div className="social-login-container">
        <div className="text-center">Or continue with</div>
        <a href="/auth/facebook" className="social"><img src="/facebook.svg" alt="facebook" /></a>
        <a href="/auth/twitter" className="social"><img src="/twitter.svg" alt="twitter" /></a>
      </div>
    </div>
  );
};

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;

export default SignIn;
