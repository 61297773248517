import { schemaShouldShowConfigTypes, schemaTypes } from './config';

const EQUITY_TAG_COLOR = 'rgb(151 125 29)';
const FUTURE_TAG_COLOR = 'rgb(66 76 157 / 92%)';

const buildClosePriceLabel = (maxInstrumentsInGroup, instrumentIndex) => {
  if (maxInstrumentsInGroup === 1) return 'Last Traded Price';

  return `item${instrumentIndex + 1} Last Traded Price`;
};

// const buildPriceLabel = (maxInstrumentsInGroup, instrumentIndex) => {
//   if (maxInstrumentsInGroup === 1) return 'Price';

//   return `item${instrumentIndex + 1} Price`;
// };

const buildDefaultLabel = (maxInstrumentsInGroup, instrumentIndex, labelName) => {
  if (maxInstrumentsInGroup === 1) return `${_.startCase(labelName)}`;

  return `item${instrumentIndex + 1} ${_.startCase(labelName)}`;
};

export const buidInstrumentOutputSchema = (maxInstrumentsInGroup) => {
  const schemas = _.flatMap(_.times(maxInstrumentsInGroup), (instrumentIndex) => {
    const equitySchema = _.flatMap(['equity'], (key) => {
      return [
        {
          name: `item${instrumentIndex}.${key}`,
          type: schemaTypes.object,
          label: 'Equity',
          category: `${key}`,
          // tagConfig: { tag: 'Equity', color: EQUITY_TAG_COLOR },
          shouldShowConfigs: [schemaShouldShowConfigTypes.pipeValuePath],
          priority: 100,
          schemaName: key
        },
        {
          name: `item${instrumentIndex}.${key}.close`,
          type: schemaTypes.number,
          label: `${buildClosePriceLabel(maxInstrumentsInGroup, instrumentIndex)}`,
          category: `${key}`,
          tagConfig: { tag: 'Equity', color: EQUITY_TAG_COLOR },
          shouldShowConfigs: [
            // schemaShouldShowConfigTypes.pipeValuePath,
            schemaShouldShowConfigTypes.entryLeftOperand,
            schemaShouldShowConfigTypes.exitLeftOperand,
            schemaShouldShowConfigTypes.adjustmentLeftOperand,
            schemaShouldShowConfigTypes.adjustmentRightOperand,
            schemaShouldShowConfigTypes.entryRightOperand,
            schemaShouldShowConfigTypes.exitRightOperand,
          ],
          priority: 100,
          schemaName: key
        },
        {
          name: `item${instrumentIndex}.${key}.volume`,
          type: schemaTypes.number,
          label: `${buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Volume')}`,
          category: `${key}`,
          tagConfig: { tag: 'Equity', color: EQUITY_TAG_COLOR },
          shouldShowConfigs: [
            // schemaShouldShowConfigTypes.pipeValuePath
          ],
          priority: 100,
          schemaName: key
        }
      ];
    });

    const monthPriceSchema = _.flatMap(['month1Price'], (key) => {
      return [
        {
          name: `item${instrumentIndex}.${key}`,
          type: schemaTypes.number,
          category: 'future',
          // tagConfig: { tag: 'Future', color: FUTURE_TAG_COLOR },
          label: 'Future',
          shouldShowConfigs: [schemaShouldShowConfigTypes.pipeValuePath],
          priority: 100,
          schemaName: key
        },
        {
          name: `item${instrumentIndex}.${key}.close`,
          type: schemaTypes.number,
          category: 'future',
          tagConfig: { tag: 'Future', color: FUTURE_TAG_COLOR },
          label: `${buildClosePriceLabel(maxInstrumentsInGroup, instrumentIndex)}`,
          shouldShowConfigs: [
            // schemaShouldShowConfigTypes.pipeValuePath,
            schemaShouldShowConfigTypes.entryLeftOperand,
            schemaShouldShowConfigTypes.exitLeftOperand,
            schemaShouldShowConfigTypes.adjustmentLeftOperand,
            schemaShouldShowConfigTypes.adjustmentRightOperand,
            schemaShouldShowConfigTypes.entryRightOperand,
            schemaShouldShowConfigTypes.exitRightOperand,
          ],
          priority: 100,
          schemaName: key
        },
        {
          name: `item${instrumentIndex}.${key}.volume`,
          type: schemaTypes.number,
          category: 'future',
          tagConfig: { tag: 'Future', color: FUTURE_TAG_COLOR },
          label: `${buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Volume')}`,
          shouldShowConfigs: [
            // schemaShouldShowConfigTypes.pipeValuePath
          ],
          priority: 100,
          schemaName: key
        },
        {
          name: `item${instrumentIndex}.${key}.openInterest`,
          type: schemaTypes.number,
          category: 'future',
          tagConfig: { tag: 'Future', color: FUTURE_TAG_COLOR },
          label: `${buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Open Interest')}`,
          shouldShowConfigs: [
            // schemaShouldShowConfigTypes.pipeValuePath
          ],
          priority: 100,
          schemaName: key
        }
      ];
    });

    return [...monthPriceSchema, ...equitySchema];
  });

  schemas.push({
    name: 'INDIAVIX',
    type: schemaTypes.object,
    label: 'INDIAVIX',
    category: 'indiaVix',
    shouldShowConfigs: [schemaShouldShowConfigTypes.pipeValuePath],
    priority: 100,
    schemaName: 'INDIAVIX'
  });

  return schemas;
};
