import { beforeSubmitSanitizer } from './beforeSubmit/index';
import onLoadSanitizer from './onLoad/index';

export const RunConfigSanitizer = {
  beforeSubmit: (runConfig) => {
    const permittedKeys = [
      'folder_id', 'quant_config', 'run_type', 'order_type', 'start_date',
      'end_date', 'is_starred', 'name', 'is_public', 'broker', 'is_webhook_enabled', 'strategy_id',
      'multiplier', 'trade_skip_start_date', 'trade_skip_end_date', 'is_strategy_subscriber', 'granularity',
      'engine_version'
    ];

    return _.pick(beforeSubmitSanitizer(runConfig), permittedKeys);
  },

  onLoad: (runConfig) => onLoadSanitizer(runConfig),
};
