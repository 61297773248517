import React from 'react';
import { runPropTypes } from 'common/propTypes';

const propTypes = {
  run: runPropTypes.isRequired
};

const defaultProps = {};

const MarketPlaceAuthorTag = ({ run }) => {
  const { strategy_id: strategyId } = run;

  return (
    <div
      className="marketplace-author-tag"
      onClick={() => { window.location.href = `/strategies/${strategyId}`; }}
    >
      <span className="material-icons-outlined tx-16 mr-1">
        storefront
      </span>

      Market Place Strategy
    </div>
  );
};

MarketPlaceAuthorTag.propTypes = propTypes;
MarketPlaceAuthorTag.defaultProps = defaultProps;

export default MarketPlaceAuthorTag;
