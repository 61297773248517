import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SEGMENT_CONFIG, STRIKE_SELECTION_TYPES } from 'v2/common/constants/index';
import { TOASTIFY_TYPES, reactToastify } from 'common/utils/reactToastify';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import {
  isIndexInstrumentPresentInInstrumentGroup, isIndexInstrumentWithWeeklyExpiryOnly
} from '../../components/InstrumentDetails/indexInstrumentsChecker';
import { useIntent } from './index';
import { IntentsContext } from '../../hoc/withIntentsContext';
import { getQuantModes } from '../../configs/index';

export const useOpenIntent = (intent) => {
  const {
    segment,
    onChangeSegment,
    segmentOptionConfigs,
    intentId,
    createOnChangeHandler,
    maxInstrumentsInGroup,
    isIndexInstrumentIntent,
    instrumentGroups,
    isIndexInstrumentWithWeeklyExpiry,
    isShowInstrumentIndex,
    onUpdateQuickAdjustments,
    onChangeIntentType,
  } = useIntent(intent);

  const {
    onUpdateIntent,
    isPipeIntent,
  } = useContext(IntentsContext);

  const {
    quantity,
    side,
    waitUntilPremiumToIncreaseInPercentage,
    waitUntilPremiumToDecreaseInPercentage,
    waitAndTradeType,
    reEntryCount,
    reExecuteCount,
    stopLoss = {},
    stopGain = {},
  } = intent;
  const optionType = intent?.instrument?.optionType;
  const expiry = intent?.instrument?.expiry;
  const strike = intent?.instrument?.strike;
  const instrumentIndex = intent?.instrument?.instrumentIndex;
  const multiplier = intent?.instrument?.strike?.multiplier || 0;
  const isStopLossPresent = !!stopLoss?.value;

  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;

  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);
  const isValidStrikeTypeForMultiplier = useCallback((strikeType) => {
    return _.includes(
      [
        STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex?.value,
        STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex?.value,
        STRIKE_SELECTION_TYPES.PremiumBased?.value,
        STRIKE_SELECTION_TYPES.IndicatorValueBasedStrikeIndex?.value,
        STRIKE_SELECTION_TYPES.DeltaBased?.value
      ], strikeType
    );
  }, []);

  const isShowMultiplier = useMemo(() => {
    return isAdvancedMode
      && segment === SEGMENT_CONFIG.option
      && isValidStrikeTypeForMultiplier(strike?.type)
      && (_.get(
        window, 'SA_FEATURE_FLAGS.shouldShowIntentMultiplier', false
      ) || window?.isAdmin || false);
  }, [isAdvancedMode, isValidStrikeTypeForMultiplier, segment, strike.type]);

  const uiBehaviourFlags = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiBehaviourFlags')
  );
  const {
    isReEntry,
    isReExecute,
    isTrailingStopLoss,
    isWaitAndTrade
  } = uiBehaviourFlags;

  const onChangeStopLoss = useCallback((newStopLoss) => {
    const newIntent = _.cloneDeep(intent);
    const { value } = newStopLoss;
    const trailingAdjustBasedOnStopLoss = value ? {} : { trailingValue: 0, adjustValue: 0 };
    newIntent.stopLoss = { ...newIntent.stopLoss, ...newStopLoss, ...trailingAdjustBasedOnStopLoss };
    if (!value) {
      newIntent.reEntryCount = 0;
      newIntent.reExecuteCount = 0;
    }
    onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const trailingConfigs = useMemo(() => {
    return stopLoss?.trail || {};
  }, [stopLoss.trail]);

  const onUpdateTrailingConfigs = useCallback((newTrailingConfigs) => {
    const newIntent = _.cloneDeep(intent);
    newIntent.stopLoss = { ...newIntent.stopLoss, trail: newTrailingConfigs };
    onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const waitAndTradeConfigs = useMemo(() => ({
    waitUntilPremiumToIncreaseInPercentage,
    waitUntilPremiumToDecreaseInPercentage,
    waitAndTradeType,
  }), [waitAndTradeType, waitUntilPremiumToDecreaseInPercentage, waitUntilPremiumToIncreaseInPercentage]);

  const onChangeWaitAndTradeConfigs = useCallback((newWaitAndTradeConfigs) => {
    onUpdateIntent({ ...intent, ...newWaitAndTradeConfigs }, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const onChangeReEntryorExecute = useCallback((newReEntryorExecuteConfigs) => {
    onUpdateIntent({ ...intent, ...newReEntryorExecuteConfigs }, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const reEntryOrExecuteOptionConfigs = useMemo(() => {
    return [
      {
        value: reEntryCount,
        label: 'Re-Entry',
        name: 'reEntryCount',
        isShow: isReEntry && !isPipeIntent,
      },
      {
        value: reExecuteCount,
        label: 'Re-Execute',
        name: 'reExecuteCount',
        isShow: isReExecute && !isPipeIntent,
      }
    ];
  }, [reEntryCount, isReEntry, reExecuteCount, isReExecute, isPipeIntent]);

  const onChangeInstrumentIndex = useCallback((newInstrumentIndex) => {
    const newIntent = _.cloneDeep(intent);
    if (newIntent.instrument.type === SEGMENT_CONFIG.equity
      && isIndexInstrumentPresentInInstrumentGroup(instrumentGroups, newInstrumentIndex)) {
      reactToastify('Index instrument is not allowed for Equity segment', TOASTIFY_TYPES.ERROR);
    } else if (isIndexInstrumentWithWeeklyExpiryOnly(instrumentGroups, newInstrumentIndex)) {
      newIntent.instrument.instrumentIndex = newInstrumentIndex;
      newIntent.instrument.expiry.cycle = 'weekly';
      onUpdateIntent(newIntent, intentId);
    } else {
      newIntent.instrument.instrumentIndex = newInstrumentIndex;
      onUpdateIntent(newIntent, intentId);
    }
  }, [intent, intentId, onUpdateIntent, instrumentGroups]);

  const onChangeStrike = useCallback((newStrike) => {
    const newIntent = _.cloneDeep(intent);
    newIntent.instrument.strike = newStrike;
    if (!isValidStrikeTypeForMultiplier(newStrike.type)) {
      newIntent.instrument.strike.multiplier = 0;
    }
    onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, isValidStrikeTypeForMultiplier, onUpdateIntent]);

  return {
    segment,
    onChangeSegment,
    optionType,
    isRetryEnabled: isReEntry && !isPipeIntent,
    isReExecuteEnabled: isReExecute && !isPipeIntent,
    isTrailingStopLossEnabled: isTrailingStopLoss && !isPipeIntent,
    isWaitAndTradeEnabled: isWaitAndTrade && !isPipeIntent,
    onChangeOptionType: createOnChangeHandler('instrument.optionType'),
    onChangeQuantity: createOnChangeHandler('quantity'),
    quantity,
    onUpdateQuickAdjustments,
    instrumentIndex,
    onChangeInstrumentIndex,
    side,
    onChangeSide: createOnChangeHandler('side'),
    expiry,
    multiplier,
    onChangeMultiplier: createOnChangeHandler('instrument.strike.multiplier'),
    onChangeExpiry: createOnChangeHandler('instrument.expiry'),
    strike,
    stopGain,
    isShowInstrumentIndex,
    isAdvancedMode,
    trailingConfigs,
    onUpdateTrailingConfigs,
    waitAndTradeConfigs,
    onChangeWaitAndTradeConfigs,
    stopLoss,
    reEntryOrExecuteOptionConfigs,
    onChangeStrike,
    isIndexInstrumentIntent,
    segmentOptionConfigs,
    onChangeStopLoss,
    isStopLossPresent,
    onChangeStopGain: createOnChangeHandler('stopGain'),
    onChangeIntentType,
    onChangeReEntryorExecute,
    intentId,
    maxInstrumentsInGroup,
    isShowMultiplier,
    isIndexInstrumentWithWeeklyExpiry,
    instrumentGroups,
  };
};
