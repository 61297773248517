import { INTENT_TYPES } from 'v2/common/constants/index';

const changeName = (value = '', oldPipeName, newPipeName) => {
  const isIncludedCombinedPremium = _.includes(value, 'combinedPremium');
  const getChangedName = (name) => name.replace(new RegExp(`\\b${oldPipeName}\\b`, 'g'), newPipeName);

  if (isIncludedCombinedPremium) {
    const splitedArray = value.split('.');
    if (_.size(splitedArray) === 2) {
      const [pipeName, ...rest] = splitedArray;
      return [getChangedName(pipeName), ...rest].join('.');
    }

    const [field, pipeName, ...rest] = splitedArray;
    return [field, getChangedName(pipeName), ...rest].join('.');
  }

  return getChangedName(value);
};

const processSignal = (signal, oldPipeName, newPipeName) => {
  const newSignal = _.cloneDeep(signal);
  newSignal.leftOperand = changeName(newSignal.leftOperand, oldPipeName, newPipeName);
  newSignal.rightOperand = changeName(newSignal.rightOperand, oldPipeName, newPipeName);

  return newSignal;
};

const processIntent = (intent, oldPipeName, newPipeName) => {
  if (intent?.type !== INTENT_TYPES.OpenBasket) {
    return intent;
  }

  const newIntent = _.cloneDeep(intent);
  newIntent.name = changeName(newIntent.name, oldPipeName, newPipeName);

  return newIntent;
};

const getSanitizedCases = (cases, oldPipeName, newPipeName) => {
  return _.map(cases, (caseConfig) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    const {
      entry: {
        signal: entrySignals,
        intents: entryIntents
      },
      adjustments,
      exitSignal: exitSignals
    } = newCaseConfig;
    const newEntrySignals = _.map(entrySignals, (signal) => processSignal(signal, oldPipeName, newPipeName));
    const newExitSignals = _.map(exitSignals, (signal) => processSignal(signal, oldPipeName, newPipeName));
    const newEntryIntents = _.map(entryIntents, (intent) => processIntent(intent, oldPipeName, newPipeName));
    const newAdjustments = _.map(adjustments, (adjustment) => {
      const { signal: adjustmentSignals, intents: adjustmentIntents } = adjustment;
      const newAdjustmentSignals = _.map(
        adjustmentSignals,
        (signal) => processSignal(signal, oldPipeName, newPipeName)
      );

      const newAdjustmentIntents = _.map(
        adjustmentIntents,
        (intent) => processIntent(intent, oldPipeName, newPipeName)
      );
      return {
        ...adjustment,
        signal: newAdjustmentSignals,
        intents: newAdjustmentIntents
      };
    });
    newCaseConfig.entry.signal = newEntrySignals;
    newCaseConfig.entry.intents = newEntryIntents;
    newCaseConfig.exitSignal = newExitSignals;
    newCaseConfig.adjustments = newAdjustments;

    return newCaseConfig;
  });
};

const getSanitizedPipeConfigs = (pipeConfigs, oldPipeName, newPipeName) => {
  return _.map(pipeConfigs, (pipeConfig) => {
    const signals = _.get(pipeConfig, 'config.signal', []);
    const newSignals = _.map(signals, (signal) => processSignal(signal, oldPipeName, newPipeName));
    const valuePaths = _.get(pipeConfig, 'config.valuePaths', []);
    const newValuePaths = _.map(valuePaths, (valuePath) => changeName(valuePath, oldPipeName, newPipeName));

    return {
      ...pipeConfig,
      config: {
        ...pipeConfig.config,
        ...(_.isEmpty(newSignals) ? {} : { signal: newSignals }),
        ...(_.isEmpty(newValuePaths) ? {} : { valuePaths: newValuePaths })
      }
    };
  });
};

export const onChangePipeName = ({
  cases,
  oldPipeName,
  newPipeName,
  pipeConfigs
}) => {
  return {
    pipeConfigs: getSanitizedPipeConfigs(pipeConfigs, oldPipeName, newPipeName),
    cases: getSanitizedCases(cases, oldPipeName, newPipeName)
  };
};
