import { useIntent } from './index';

export const useModifyExistingIntent = (intent) => {
  const {
    createOnChangeHandler,
    onChangeIntentType
  } = useIntent(intent);
  const {
    type,
    target = '',
  } = intent;

  return {
    type,
    target,
    onChangeTarget: createOnChangeHandler('target.label'),
    onChangeIntentType
  };
};
