import React, { useEffect, useState } from 'react';
import { userPropTypes } from 'common/propTypes';
import { automaticSupportedBrokers, availableBrokers } from 'common/brokerConfigs/config';
import Modal from 'common/components/ModalComponent';
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage';

const propTypes = { currentUser: userPropTypes };
const defaultProps = { currentUser: null };

const TIME_RANGE = 120;
const LOCAL_STORAGE_KEY = 'market_place_not_supported_message';

const StrategyErrorMessage = ({ currentUser }) => {
  const { isUserSignedIn } = window;
  const [isOpen, setIsOpen] = useState(false);
  const broker = _.get(currentUser, 'broker', '');
  const oneIndentation = ' ';
  const isShowModal = !_.includes(automaticSupportedBrokers, broker) && isUserSignedIn && isOpen;

  const handleOpenModal = () => {
    setIsOpen(true);
    setLocalStorage(LOCAL_STORAGE_KEY, new Date());
  };

  useEffect(() => {
    const localStorageTime = getLocalStorage(LOCAL_STORAGE_KEY);
    if (localStorageTime) {
      const updatedTime = new Date();
      updatedTime.setTime(new Date(localStorageTime).getTime() + (TIME_RANGE * 60 * 1000));
      if (new Date() > updatedTime) { handleOpenModal(); }
    } else { handleOpenModal(); }
  }, []);

  if (!isShowModal) return null;

  let content = '';
  if (!broker) {
    content = 'For broker integration and order execution,you need to login via your broker account in';
    content += 'QuantMan. You are logged in using your email/facebook/twitter. ';
    content += 'So, you cannot execute orders in QuantMan.';
  } else {
    content = 'For automatic order execution, you need to login via your automatic supported broker';
    content += ` account in QuantMan. you are logged in using your 
    ${availableBrokers[broker] ? availableBrokers[broker] : broker} account, which is not`;
    content += ' supported for market place';
  }

  return (
    <Modal size="md" backdrop="static" onClose={() => setIsOpen(false)}>
      <div className="text-center">
        <h4>
          Your account is not supported for
          {oneIndentation}
          <b>Marketplace</b>
        </h4>
        <img src="/user-alert.svg" alt="user-alert" className="alert-modal-img my-4" />
        <p>{content}</p>
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="btn btn-outline-primary btn-pill my-3"
        >
          Continue browsing
        </button>
      </div>
    </Modal>
  );
};

StrategyErrorMessage.propTypes = propTypes;
StrategyErrorMessage.defaultProps = defaultProps;

export default StrategyErrorMessage;
