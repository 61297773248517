import React from 'react';
import PropTypes from 'prop-types';

import { INTENT_TYPES } from 'v2/common/constants/index';

const propTypes = {
  isAdjustmentIntent: PropTypes.bool,
  value: PropTypes.oneOf(Object.values(INTENT_TYPES)).isRequired,
  onChange: PropTypes.func.isRequired
};
const defaultProps = {
  isAdjustmentIntent: false
};

const IntentTypeSelector = ({ isAdjustmentIntent, value, onChange }) => {
  return (
    <div className="transaction_details-items intent-type">
      <select
        className="intent-type-selector"
        disabled={!isAdjustmentIntent}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value={INTENT_TYPES.OpenLeg}>Open</option>
        <option value={INTENT_TYPES.CloseLeg}>Close</option>
        <option value={INTENT_TYPES.UpdateStopLoss}>MTC</option>
      </select>
    </div>
  );
};

IntentTypeSelector.propTypes = propTypes;
IntentTypeSelector.defaultProps = defaultProps;

export default IntentTypeSelector;
