import React from 'react';
import PropTypes from 'prop-types';
import EntryOrExitOrderTypes from './EntryOrExitOrderTypes';

const propTypes = {
  entryOrderType: PropTypes.string.isRequired,
  entryOrderModification: PropTypes.shape({}).isRequired,
  exitOrderType: PropTypes.string.isRequired,
  exitOrderModification: PropTypes.shape({}).isRequired,
  onChangeOrderTypeConfigs: PropTypes.func.isRequired,
};

const defaultProps = {};

const OrderType = ({
  entryOrderType,
  entryOrderModification,
  exitOrderType,
  exitOrderModification,
  onChangeOrderTypeConfigs
}) => {
  const onHandleEntryTypeAndModification = ({ orderType, orderModification }) => {
    onChangeOrderTypeConfigs({ entryOrderType: orderType, entryOrderModification: orderModification });
  };

  const onHandleExitTypeAndModification = ({ orderType, orderModification }) => {
    onChangeOrderTypeConfigs({ exitOrderType: orderType, exitOrderModification: orderModification });
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 mb-4">
          <div className="row">
            <div className="col-md-2">
              <label className="label_style mb-0">Entry Orders</label>
            </div>
            <div className="col-md-10">
              <EntryOrExitOrderTypes
                orderType={entryOrderType}
                orderModification={entryOrderModification}
                onHandleTypeAndModification={onHandleEntryTypeAndModification}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-md-2">
              <label className="label_style mb-0">Exit Orders</label>
            </div>
            <div className="col-md-10">
              <EntryOrExitOrderTypes
                orderType={exitOrderType}
                orderModification={exitOrderModification}
                onHandleTypeAndModification={onHandleExitTypeAndModification}
                isExitOrder
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OrderType.propTypes = propTypes;
OrderType.defaultProps = defaultProps;

export default OrderType;
