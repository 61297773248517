import React from 'react';
import PropTypes from 'prop-types';

import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const CopyTextButton = ({ text }) => {
  const onCopy = () => {
    navigator.clipboard.writeText(text);
    reactToastify('Copied to clipboard', TOASTIFY_TYPES.SUCCESS, {
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  return (
    <span className="d-inline-block">
      <span className="d-flex align-items-center gap-10">
        <span>{text}</span>
        <button type="button" className="btn btn-secondary" onClick={onCopy}>
          <span className="material-icons-outlined mr-1 tx-20 align-text-bottom">
            content_copy
          </span>
          Copy
        </button>
      </span>
    </span>
  );
};

CopyTextButton.propTypes = {
  text: PropTypes.string.isRequired,
};
CopyTextButton.defaultProps = {};

export default CopyTextButton;
