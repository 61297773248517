import { SEGMENT_CONFIG } from 'common/constants/index';

export {
  OPTIONS_EXPIRY,
  WEEKLY_OPTION_AVAILABLE_FROM,
  STOPS_VALUE_TYPES
} from 'modules/QuantBuilder/config';

export const STRIKE_KEYS = {
  strikeIndex: 'strikeIndex',
  premium: 'premium',
  // standardDeviation: 'standardDeviation',
  valuePath: 'valuePath',
  // indicatorValue: 'indicatorValue',
  strikePrice: 'strikePrice',
  equityPrice: 'equityPrice',
  // maximumOpenInterest: 'maximumOpenInterest'
};

export const onValidateStrikeBasedOn = (strikeBasedOn) => {
  if (_.includes([STRIKE_KEYS.indicatorValue, STRIKE_KEYS.maximumOpenInterest, STRIKE_KEYS.equityPrice],
    strikeBasedOn)) {
    return STRIKE_KEYS.strikeIndex;
  }

  return strikeBasedOn;
};

export const waitAndTradeTypesAndNode = {
  WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE: 'waitUntilPremiumToIncreaseInPercentage',
  WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE: 'waitUntilPremiumToDecreaseInPercentage',
};

export const getEmptyOptionConfigs = () => ({
  optionType: '',
  entryType: '',
  quantity: 1,
  strikeIndex: 0,
  stopGain: undefined,
  stopLoss: undefined,
  stopGainPrice: undefined,
  stopLossPrice: undefined,
  sortId: 0,
  trailingStopLoss: {},
  reExecuteCount: 0,
  reEntryCount: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
  waitAndTradeType: waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE,
});

export const getStrikeValue = (strikeKey, defaultValue) => {
  if (strikeKey !== STRIKE_KEYS.standardDeviation) return defaultValue;

  return Math.abs(defaultValue) === 5 ? 1 : 2;
};

export const getoptionStrategies = (basedOn, basedOnValue = 0) => {
  const emptyOptionConfigs = _.omit(getEmptyOptionConfigs(), STRIKE_KEYS.strikeIndex);
  return ({
    buyCall: {
      id: 'buy-call',
      name: 'buyCall',
      icon: '/options-builder-icon/bullish-buy-call.svg',
      optionConfigs: [{
        ...emptyOptionConfigs,
        entryType: 'buy',
        optionType: 'CE',
        [basedOn]: basedOnValue,
        quantity: 1
      }]
    },

    sellPut: {
      id: 'sell-put',
      name: 'sellPut',
      icon: '/options-builder-icon/bullish-sell-put.svg',
      optionConfigs: [{
        ...emptyOptionConfigs,
        entryType: 'sell',
        optionType: 'PE',
        [basedOn]: basedOnValue,
        quantity: 1
      }]
    },

    bullCallSpread: {
      id: 'bull-call-spread',
      name: 'bullCallSpread',
      icon: '/options-builder-icon/bullish-bull-call-spread.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'CE',
          [basedOn]: basedOnValue,
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 5),
          quantity: 1,
          sortId: 1
        }
      ]
    },

    bullPutSpread: {
      id: 'bull-put-spread',
      name: 'bullPutSpread',
      icon: '/options-builder-icon/bullish-bull-put-spread.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: basedOnValue,
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -5),
          quantity: 1,
          sortId: 1
        }
      ]
    },

    bearPutSpread: {
      id: 'bear-put-spread',
      name: 'bearPutSpread',
      icon: '/options-builder-icon/bearish-bear-put-spread.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'PE',
          [basedOn]: basedOnValue,
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -5),
          quantity: 1,
          sortId: 1
        }
      ]
    },

    bearCallSpread: {
      id: 'bear-call-spread',
      name: 'bearCallSpread',
      icon: '/options-builder-icon/bearish-bear-call-spread.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: basedOnValue,
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 5),
          quantity: 1,
          sortId: 1
        }
      ]
    },

    buyPut: {
      id: 'buy-put',
      name: 'buyPut',
      icon: '/options-builder-icon/bearish-buy-put.svg',
      optionConfigs: [{
        ...emptyOptionConfigs,
        entryType: 'buy',
        optionType: 'PE',
        [basedOn]: 0,
        quantity: 1
      }]
    },

    shortStraddle: {
      id: 'straddle-tab',
      name: 'shortStraddle',
      icon: '/options-builder-icon/neutral-short-straddle.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: 0,
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: 0,
          quantity: 1,
          sortId: 1
        }
      ]
    },

    sellCall: {
      id: 'sell-call',
      name: 'sellCall',
      icon: '/options-builder-icon/bearish-sell-call.svg',
      optionConfigs: [{
        ...emptyOptionConfigs,
        entryType: 'sell',
        optionType: 'CE',
        [basedOn]: 0,
        quantity: 1,
        sortId: 1
      }]
    },

    shortStrangle: {
      id: 'strangle-tab',
      name: 'shortStrangle',
      icon: '/options-builder-icon/neutral-short-strangle.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -5),
          quantity: 1,
          sortId: 0
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 5),
          quantity: 1,
          sortId: 1
        }
      ]
    },

    ironButterfly: {
      id: 'iron-butterfly',
      name: 'ironButterfly',
      icon: '/options-builder-icon/neutral-iron-butterfly.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: 0,
          quantity: 1,
          sortId: 0
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: 0,
          quantity: 1,
          sortId: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -5),
          quantity: 1,
          sortId: 2
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 5),
          quantity: 1,
          sortId: 3
        }
      ]
    },

    ironCondor: {
      id: 'iron-condor',
      name: 'ironCondor',
      icon: '/options-builder-icon/neutral-short-iron-condor.svg',
      optionConfigs: [
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 5),
          quantity: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'sell',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -5),
          quantity: 1,
          sortId: 1
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'CE',
          [basedOn]: getStrikeValue(basedOn, 8),
          quantity: 1,
          sortId: 2
        },
        {
          ...emptyOptionConfigs,
          entryType: 'buy',
          optionType: 'PE',
          [basedOn]: getStrikeValue(basedOn, -8),
          quantity: 1,
          sortId: 3
        },
      ]
    }
  });
};

export const getOptionConfigStrategies = (basedOn) => {
  return ({
    custom: {},
    bullish: {
      buyCall: getoptionStrategies(basedOn).buyCall,
      sellPut: getoptionStrategies(basedOn).sellPut,
      bullCallSpread: getoptionStrategies(basedOn).bullCallSpread,
      bullPutSpread: getoptionStrategies(basedOn).bullPutSpread
    },

    bearish: {
      buyPut: getoptionStrategies(basedOn).buyPut,
      sellCall: getoptionStrategies(basedOn).sellCall,
      bearPutSpread: getoptionStrategies(basedOn).bearPutSpread,
      bearCallSpread: getoptionStrategies(basedOn).bearCallSpread
    },

    neutral: {
      shortStraddle: getoptionStrategies(basedOn).shortStraddle,
      shortStrangle: getoptionStrategies(basedOn).shortStrangle,
      ironButterfly: getoptionStrategies(basedOn).ironButterfly,
      ironCondor: getoptionStrategies(basedOn).ironCondor
    }
  });
};

export const getEmptyEquityConfig = () => ({
  quantity: 1,
  amount: 0,
  entryType: '',
  stopGain: undefined,
  stopLoss: undefined,
  stopGainPrice: undefined,
  stopLossPrice: undefined,
  trailingStopLoss: {},
  reExecuteCount: 0,
  reEntryCount: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
  waitAndTradeType: waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE,
});

export const getEmptyFutureConfig = () => ({
  quantityExpression: '1',
  monthIndex: 1,
  entryType: '',
  stopGain: undefined,
  stopLoss: undefined,
  stopGainPrice: undefined,
  stopLossPrice: undefined,
  trailingStopLoss: {},
  reExecuteCount: 0,
  reEntryCount: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
  waitAndTradeType: waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE,
});

export const TRAILING_PERCENTAGE = 'trailingPercentage';
export const ADJUST_PERCENTAGE = 'adjustPercentage';

export const getEmptyTransactionConfig = () => ({
  mode: {
    transaction: 'option',
    optionStrategy: 'custom',
    optionSubStrategy: '',
  },
  futureConfig: getEmptyFutureConfig(),
  optionConfigs: [].concat(getEmptyOptionConfigs()),
  equityConfig: getEmptyEquityConfig(),
  quantityExpressionDetails: {
    quantity: 0,
    operator: '',
    indicator: '',
  },
});

export const getDefaultExistingLegConfig = {
  legType: '', action: '', optionConfigIndex: null
};

export const getEmptyAdjustmentActionConfig = () => {
  const emptyTransactionConfig = getEmptyTransactionConfig();

  return ({
    ...{ ...emptyTransactionConfig, optionConfigs: [] },
    existingLegConfigs: [] // next phase
  });
};

export const existingLegTypes = [
  { label: 'Equity', value: 'equity' },
  { label: 'Option', value: 'option' },
  { label: 'Future', value: 'future' }
];

export const existingLegActionTypes = [
  { label: 'Close Leg', value: 'close' },
  { label: 'Move to cost', value: 'updateStopLoss' }
];

export const TRANSACTION_TYPE_OPTIONS = { B: 'buy', S: 'sell' };

export const getStrikeIndexDisplayName = (value, optionType, valuePath) => {
  if (value === 0) {
    return valuePath || 'ATM';
  }

  if (value > 0) return (`${optionType === 'CE' ? 'OTM' : 'ITM'} ${Math.abs(value)}`);

  return (`${optionType === 'CE' ? 'ITM' : 'OTM'} ${Math.abs(value)}`);
};

export const getEligibleIndicatorForStrike = (outputSchemas) => (
  _.filter(outputSchemas, (schema) => (
    _.includes(['pivotPoints', 'superTrend', 'todayCandle'], _.get(schema, 'indicatorType', ''))
  ))
);

export const getValuePathOptions = (outputSchemas) => {
  const eligibleStrikeIndicators = getEligibleIndicatorForStrike(outputSchemas);
  if (_.isEmpty(eligibleStrikeIndicators)) return [];

  return _.map(eligibleStrikeIndicators, ({ name }) => ({
    value: name,
    displayName: name
  }));
};

export const hasIndexInstruments = (instrumentGroups, transactionIndex) => {
  if (_.isEmpty(instrumentGroups)) return false;

  const { indexInstruments } = window;
  return _.every(
    _.map(instrumentGroups, (instrumentGroup) => (
      _.includes(indexInstruments, instrumentGroup[transactionIndex])
    ))
  );
};
const getStrikeRange = (instrumentGroups, transactionIndex) => (
  hasIndexInstruments(instrumentGroups, transactionIndex)
    ? _.range(-30, 31)
    : _.range(-10, 11)
);

export const getStandardDeviationName = (value) => (!value ? 'ATM' : `${value} SD`);
export const getMaximumOpenInterest = (value) => (!value ? 'Max Open Interest' : '2nd Max Open Interest');

export const getOptionConfigSettings = (optionType, valuePath, instrumentGroups, transactionConfigIdx) => ({
  strikeIndex: {
    title: 'Strike Index',
    options: _.map(getStrikeRange(instrumentGroups, transactionConfigIdx), (value) => ({
      value,
      displayName: getStrikeIndexDisplayName(value, optionType)
    }))
  },
  equityPrice: {
    title: 'Strike Index',
    options: _.map(getStrikeRange(instrumentGroups, transactionConfigIdx), (value) => ({
      value,
      displayName: getStrikeIndexDisplayName(value, optionType)
    }))
  },
  standardDeviation: {
    title: 'Standard Deviation',
    options: _.map(_.range(0, 4), (value) => ({
      value,
      displayName: getStandardDeviationName(value)
    }))
  },
  maximumOpenInterest: {
    title: 'Maximum Open Interest',
    options: _.map(_.range(0, 2), (value) => ({
      value,
      displayName: getMaximumOpenInterest(value)
    }))
  },
  indicatorValue: {
    title: 'Indicator Value',
    options: _.map(getStrikeRange(instrumentGroups, transactionConfigIdx), (value) => ({
      value,
      displayName: getStrikeIndexDisplayName(value, optionType, valuePath)
    }))
  },
  optionTypes: ['CE', 'PE'],
  entryTypes: TRANSACTION_TYPE_OPTIONS
});

export const TRADE_TYPES = {
  option: 'option',
  future: 'future'
};

export const getEmptyTransactionBuilderConfig = () => ({
  tradeType: 'optionConfigs',
  strike: 0,
  valuePath: '',
  optionType: '',
  quantity: '1',
  transactionType: '',
  stopGain: undefined,
  stopLoss: undefined,
  stopGainPrice: undefined,
  stopLossPrice: undefined,
  sortId: null,
  reEntryCount: 0,
  reExecuteCount: 0,
  trailingPercentage: undefined,
  adjustPercentage: undefined,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
  [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
  waitAndTradeType: waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE,
});

export const TRANSACTION_MODES = {
  futureConfig: 'future',
  optionConfigs: 'option',
  equityConfig: 'equity',
  futureAndOption: 'futureAndOption',
  futureAndEquity: 'futureAndEquity',
  optionAndEquity: 'optionAndEquity',
  combinedAllConfigs: 'all'
};

export const OPTION_TYPES = ['CE', 'PE'];
export const TRANSACTION_TYPES = ['buy', 'sell'];

const { applicationDomain, isStrategyCreator } = window;
const isAllowManualSignals = _.get(applicationDomain, 'config.allow_manual_signals', false);

export const getStrikeBasedOn = () => ({
  strikeIndex: {
    value: 'strikeIndex',
    displayName: 'Future Based Strike Index',
    shouldShow: true
  },
  equityPrice: {
    value: 'equityPrice',
    displayName: 'Spot Based Strike Index',
    shouldShow: true
  },
  premium: {
    value: 'premium',
    displayName: 'Premium',
    shouldShow: true
  },
  // standardDeviation: {
  //   value: 'standardDeviation',
  //   displayName: 'Standard Deviation',
  //   shouldShow: true
  // },
  // indicatorValue: {
  //   value: 'indicatorValue',
  //   displayName: 'Indicator Value',
  //   shouldShow: false
  // },
  // maximumOpenInterest: {
  //   value: 'maximumOpenInterest',
  //   displayName: 'Maximum Open Interest',
  //   shouldShow: false
  // },
  strikePrice: {
    value: 'strikePrice',
    displayName: 'Strike Price',
    shouldShow: isAllowManualSignals || isStrategyCreator
  }
});

export const getStrikeDisplayName = (optionConfig) => {
  const {
    strikeIndex, valuePath, maximumOpenInterest, standardDeviation
  } = STRIKE_KEYS;
  const optionConfigKeys = _.keys(optionConfig);

  if (_.includes(optionConfigKeys, valuePath)) {
    const strike = !optionConfig[strikeIndex]
      ? ''
      : optionConfig[strikeIndex];

    return [_.startCase(optionConfig[valuePath]), strike];
  }

  return _.map(optionConfig, (value, key) => {
    if (!_.includes(_.keys(STRIKE_KEYS), key)) return '';
    if (key === strikeIndex) { return getStrikeIndexDisplayName(value, optionConfig.optionType); }
    if (key === maximumOpenInterest) return `${_.startCase(key)} ${getMaximumOpenInterest(value)}`;
    if (key === standardDeviation) return `${_.startCase(key)} ${getStandardDeviationName(value)}`;

    return `${_.startCase(key)} ${_.startCase(value)}`;
  });
};

const getEmptyStopGainConfig = () => ({
  stopGain: undefined,
  stopGainPrice: undefined
});

const getEmptyStopLossConfig = () => ({
  stopLoss: undefined,
  stopLossPrice: undefined
});

export const stopsConfig = {
  stopGain: {
    percent: {
      key: 'stopGain',
      config: getEmptyStopGainConfig()
    },
    price: {
      key: 'stopGain',
      config: getEmptyStopGainConfig()
    }
  },
  stopLoss: {
    percent: {
      key: 'stopLoss',
      config: getEmptyStopLossConfig()
    },
    price: {
      key: 'stopLoss',
      config: getEmptyStopLossConfig()
    }
  }
};

export const getSegmentStrikeBasedOn = (segment, filteredBasedOn) => {
  switch (segment) {
    case SEGMENT_CONFIG.equity:
      return _.filter(filteredBasedOn, ({ value }) => _.includes(
        [
          STRIKE_KEYS.premium, STRIKE_KEYS.standardDeviation, STRIKE_KEYS.equityPrice, STRIKE_KEYS.strikePrice
        ],
        value
      ));
    case SEGMENT_CONFIG.future:
      return _.filter(filteredBasedOn, ({ value }) => _.includes(
        [
          STRIKE_KEYS.premium, STRIKE_KEYS.standardDeviation, STRIKE_KEYS.strikeIndex, STRIKE_KEYS.strikePrice
        ],
        value
      ));
    default:
      return filteredBasedOn;
  }
};
