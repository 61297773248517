import { getUrlParams } from 'common/utils/urlUtils';
import React from 'react';

const propTypes = {};
const defaultProps = {};

const StrategyBanner = () => {
  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
  if (isMinifiedPage) return <div className="mt-5" />;

  return (
    <div className="marketplace-banner d-none d-lg-block master-domain-only">
      <div className="container">
        <h1 className="text-white tx-30 font-weight-bold">Marketplace</h1>
        <p className="tx-16 text-white mt-1">
          Explore a world of trading strategies! A marketplace where you choose and deploy
          from professionally curated strategies.
        </p>
        <button
          type="button"
          className="goto-btn btn btn-success text-nowrap btn-pill"
          onClick={() => { window.location.href = '/strategies'; }}
        >
          <i className="material-icons-outlined tx-18 align-middle mr-1">arrow_back</i>
          Go to Marketplace
        </button>
      </div>
    </div>
  );
};

StrategyBanner.propTypes = propTypes;
StrategyBanner.defaultProps = defaultProps;

export default StrategyBanner;
