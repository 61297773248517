import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import StopsValueTypeToggleBtn from 'common/components/StopsValueTypeToggleBtn';
import
StrikeIndex
  from 'modules/TransactionsBuilder/NewTransactionBuilder/components/TransactionBuilderForm/StrikeIndex';
import { optionConfigPropTypes, instrumentGroupsPropTypes, timeBasedSignalPropTypes } from 'common/propTypes';
import { onValidateStrikeBasedOn, STRIKE_KEYS } from 'modules/TransactionsBuilder/configs';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import {
  STOPS_KEYS, STOPS_VALUE_TYPES
} from '../config';

const propTypes = {
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  optionConfigs: PropTypes.arrayOf(optionConfigPropTypes),
  onOptionConfigsChange: PropTypes.func.isRequired,
  optionStrikeCalculationBasedOn: PropTypes.string.isRequired,
  timeBasedSignal: timeBasedSignalPropTypes.isRequired
};
const defaultProps = {};

const OptionConfigForm = ({
  instrumentGroups,
  optionConfigs,
  onOptionConfigsChange,
  optionStrikeCalculationBasedOn,
  timeBasedSignal,
}) => {
  const { PRICE, PERCENT } = STOPS_VALUE_TYPES;
  const {
    STOP_GAIN, STOP_GAIN_PRICE, STOP_LOSS, STOP_LOSS_PRICE
  } = STOPS_KEYS;
  const [stopGainValueKey, setStopGainValueKey] = useState(STOP_GAIN);
  const [stopLossValueKey, setStopLossValueKey] = useState(STOP_LOSS);

  const onChangeOptionConfigs = (newOptionConfig, indexToEdit) => {
    const newOptionConfigs = optionConfigs;
    const editedOptionConfig = optionStrikeCalculationBasedOn in newOptionConfig
      ? _.omit(optionConfigs[indexToEdit], STRIKE_KEYS.strikeIndex)
      : optionConfigs[indexToEdit];

    newOptionConfigs[indexToEdit] = {
      ...editedOptionConfig,
      ...newOptionConfig
    };

    onOptionConfigsChange(newOptionConfigs, timeBasedSignal);
  };

  const onStopGainValueTypeChange = (newValueType, idx) => {
    setStopGainValueKey(
      newValueType === PRICE ? STOP_GAIN_PRICE : STOP_GAIN
    );

    onChangeOptionConfigs({
      stopGain: undefined,
      stopGainPrice: undefined,
    }, idx);
  };

  const onStopLossValueTypeChange = (newValueType, idx) => {
    setStopLossValueKey(
      newValueType === PRICE ? STOP_LOSS_PRICE : STOP_LOSS
    );

    onChangeOptionConfigs({
      stopLoss: undefined,
      stopLossPrice: undefined,
    }, idx);
  };

  const renderOptionConfig = (optionConfig, idx) => {
    const {
      entryType, optionType, quantity
    } = optionConfig;

    const strikeIndexKey = onValidateStrikeBasedOn(optionStrikeCalculationBasedOn);
    const strike = _.get(optionConfig, strikeIndexKey, 0);

    const onChangeValue = (key, event, isString) => {
      onChangeOptionConfigs({ [key]: onRegexOnlyNumberAndFloat(event.target.value, isString) }, idx);
    };

    const renderValue = (key) => {
      if (optionConfig[key] === undefined) return '';
      if (optionConfig[key]) return optionConfig[key].toString();

      return '';
    };

    return (
      <tr key={idx}>
        <td>
          <ToggleButtonGroup
            bsPrefix="btn-group btn-group-sm btn-group-toggle"
            type="radio"
            name="optionType"
            value={optionType}
            onChange={(newOptionType) => onChangeOptionConfigs({ optionType: newOptionType }, idx)}
          >
            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
              value="CE"
            >
              Call
            </ToggleButton>

            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
              value="PE"
            >
              Put
            </ToggleButton>
          </ToggleButtonGroup>
        </td>

        <td>
          <StrikeIndex
            strike={strike}
            instrumentGroups={instrumentGroups}
            onStrikeChange={(newStrike) => onChangeOptionConfigs({
              [strikeIndexKey]: newStrike.strike
            }, idx)}
            optionType={optionType}
            transactionConfigIdx={0}
            strikeBasedOn={optionStrikeCalculationBasedOn}
            valuePath=""
          />
        </td>

        <td>
          <input
            className="form-control form-control-sm total-lot"
            value={quantity}
            type="number"
            onChange={(event) => onChangeOptionConfigs({ quantity: event.target.value }, idx)}
          />
        </td>

        <td>
          <ToggleButtonGroup
            bsPrefix="btn-group btn-group-sm btn-group-toggle"
            type="radio"
            name="entryType"
            value={entryType}
            onChange={(newEntryType) => onChangeOptionConfigs({ entryType: newEntryType }, idx)}
          >
            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
              value="buy"
            >
              Buy
            </ToggleButton>

            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
              value="sell"
            >
              Sell
            </ToggleButton>
          </ToggleButtonGroup>
        </td>

        <td>
          <div className="input-group overall-stops">
            <StopsValueTypeToggleBtn
              valueType={stopLossValueKey === STOP_LOSS ? PERCENT : PRICE}
              onValueTypeChange={(newValueType) => onStopLossValueTypeChange(newValueType, idx)}
            />
            <input
              className="form-control form-control-sm"
              type="text"
              value={renderValue(stopLossValueKey)}
              onBlur={(event) => { onChangeValue(stopLossValueKey, event, false); }}
              onChange={(event) => { onChangeValue(stopLossValueKey, event, true); }}
            />
          </div>
        </td>

        <td>
          <div className="input-group overall-stops">
            <StopsValueTypeToggleBtn
              valueType={stopGainValueKey === STOP_GAIN ? PERCENT : PRICE}
              onValueTypeChange={(newValueType) => onStopGainValueTypeChange(newValueType, idx)}
            />
            <input
              className="form-control form-control-sm"
              type="text"
              value={renderValue(stopGainValueKey)}
              onBlur={(event) => { onChangeValue(stopGainValueKey, event, false); }}
              onChange={(event) => { onChangeValue(stopGainValueKey, event, true); }}
            />
          </div>
        </td>
      </tr>
    );
  };

  return _.map(optionConfigs, renderOptionConfig);
};

OptionConfigForm.propTypes = propTypes;
OptionConfigForm.defaultProps = defaultProps;

export default OptionConfigForm;
