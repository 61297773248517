import React from 'react';
import PropTypes from 'prop-types';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  onDeletePipeConfig: PropTypes.func,
  onEditPipeConfig: PropTypes.func,
  isDependent: PropTypes.bool,
};
const defaultProps = {
  onDeletePipeConfig: () => {},
  onEditPipeConfig: () => {},
  isDependent: false
};

const EditOrDeleteButton = ({
  onDeletePipeConfig, onEditPipeConfig, isDependent
}) => {
  return (
    <div className="d-flex align-items-center actions-btn">
      <HoverMessage
        message={isDependent ? 'Please remove the usage and then delete this indicator.' : 'Delete'}
        popClassName="d-sm-none d-md-block custom-popover"
      >
        <button
          type="button"
          disabled={isDependent}
          className="btn btn-delete-outline mr-2"
          onClick={() => {
            if (isDependent) {
              return reactToastify('Cannot delete dependent indicator', TOASTIFY_TYPES.ERROR);
            }
            return onDeletePipeConfig();
          }}
        >
          <i />
          <span>Delete</span>
        </button>
      </HoverMessage>
      <button type="button" className="btn btn-edit-fill py-1 px-2" onClick={onEditPipeConfig}>
        <i className="edit-icon" />
        <span>Edit</span>
      </button>
    </div>
  );
};

EditOrDeleteButton.propTypes = propTypes;
EditOrDeleteButton.defaultProps = defaultProps;

export default EditOrDeleteButton;
