import React from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes } from 'common/propTypes';
import classNames from 'classnames';
import SignalsView from 'v2/modules/withRunForm/components/Signals/SignalsView/index';
import IntentsView from 'v2/modules/withRunForm/components/Intents/IntentsView/index';

const propTypes = {
  isSignal: PropTypes.bool.isRequired,
  config: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {};

const ViewConfigSentences = ({
  isSignal,
  config,
  title,
  outputSchema
}) => {
  const divClassName = classNames('row align-items-center', {
    signal: isSignal,
    transaction: !isSignal
  });

  return (
    <div className={divClassName}>
      <div className="col-md-2">
        <label className="col-form-label-sm mb-0">{title}</label>
      </div>

      <div className="col-md-10 config-wrapper signal-info">
        {isSignal
          ? <SignalsView isViewOnly signals={config} outputSchema={outputSchema} />
          : <IntentsView intents={config} outputSchema={outputSchema} />}
      </div>
    </div>

  );
};

ViewConfigSentences.defaultProps = defaultProps;
ViewConfigSentences.propTypes = propTypes;

export default ViewConfigSentences;
