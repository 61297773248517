import React from 'react';
import PropTypes from 'prop-types';
import {
  EXPIRY_MONTHLY_DETAILS, EXPIRY_WEEKLY_DETAILS, INTENT_TYPES, SEGMENT_CONFIG,
  WAIT_AND_TRADE_TYPES
} from 'v2/common/constants/index';
import { outputSchemaPropTypes } from 'common/propTypes';
import { getStrikeStatement } from './configs';
import StopConfigView from './StopConfigView';
import QuickAdjustmentsView from './QuickAdjustmentsView';

const propTypes = {
  intents: PropTypes.arrayOf(PropTypes.shape({})),
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {
  intents: []
};

const IntentsView = ({ intents, outputSchema }) => {
  const renderWaitAndTrade = (config) => {
    const waitAndTradeType = _.get(config, 'waitAndTradeType', '');
    const waitUntilPremiumToIncreaseInPercentage = _.get(
      config, 'waitUntilPremiumToIncreaseInPercentage', 0
    );
    const waitUntilPremiumToDecreaseInPercentage = _.get(
      config, 'waitUntilPremiumToDecreaseInPercentage', 0
    );
    if (!waitUntilPremiumToIncreaseInPercentage && !waitUntilPremiumToDecreaseInPercentage) return null;

    if (waitAndTradeType === WAIT_AND_TRADE_TYPES.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE) {
      return ` and WaitandTrade Decrease - ${waitUntilPremiumToDecreaseInPercentage}%`;
    }

    return ` and WaitandTrade Increase - ${waitUntilPremiumToIncreaseInPercentage}%`;
  };

  const renderStopConfig = (config, type = 'StopLoss') => {
    return (
      <StopConfigView config={config} type={type} />
    );
  };

  const renderReExecute = (reExecuteCount) => {
    if (_.isUndefined(reExecuteCount) || !reExecuteCount) return null;

    return ` and Re-Execute ${reExecuteCount}`;
  };

  const renderReEntry = (reEntryCount) => {
    if (_.isUndefined(reEntryCount) || !reEntryCount) return null;

    return ` and Re-Entry ${reEntryCount}`;
  };

  const renderQuantity = (quantity, type) => {
    return (
      <>
        {quantity}
        &nbsp;
        {type === SEGMENT_CONFIG.equity ? 'quantity' : 'lot'}
      </>
    );
  };

  const renderOptionIntent = (intent) => {
    const {
      identifier: { label },
      side,
      instrument: {
        type, expiry: { index, cycle }, optionType, strike
      },
      stopLoss,
      quantity,
      stopGain,
      reEntryCount,
      reExecuteCount,
      uiConfigs: { quickAdjustments = [] } = {}
    } = intent;
    const expiry = _.get({ ...EXPIRY_MONTHLY_DETAILS, ...EXPIRY_WEEKLY_DETAILS }, `${index}-${cycle}`, {});

    return (
      <span
        className="badge badge-light badge-pill mr-1 border text-wrap"
        key={`option-order-details-${index}`}
      >
        <span className="font-weight-bold">
          Leg
          {label}
        </span>
        &nbsp;
        {_.startCase(side)}
        &nbsp;
        {expiry?.label || ''}
        &nbsp;
        {_.startCase(type)}
        &nbsp;
        {_.startCase(optionType)}
        &nbsp;
        {renderQuantity(quantity, type)}
        &nbsp;
        {getStrikeStatement(strike, optionType, outputSchema)}
        {renderStopConfig(stopLoss)}
        {renderStopConfig(stopGain, 'StopGain')}
        {renderWaitAndTrade(intent)}
        {renderReExecute(reExecuteCount)}
        {renderReEntry(reEntryCount)}
        <QuickAdjustmentsView quickAdjustments={quickAdjustments} />
      </span>
    );
  };

  const renderFutureOrEquityIntent = (intent) => {
    const {
      identifier: { label },
      instrument: { type },
      stopLoss,
      side,
      stopGain,
      quantity,
      reEntryCount,
      reExecuteCount,
      uiConfigs: { quickAdjustments = [] } = {}
    } = intent;

    return (
      <span
        className="badge badge-light badge-pill mr-1 border text-wrap"
        key={`option-order-details-${type}`}
      >
        <span className="font-weight-bold">
          Leg
          {label}
        </span>
        &nbsp;
        {_.startCase(type)}
        &nbsp;
        {_.startCase(side)}
        &nbsp;
        {renderQuantity(quantity, type)}
        &nbsp;
        {renderStopConfig(stopLoss)}
        {renderStopConfig(stopGain, 'StopGain')}
        {renderWaitAndTrade(intent)}
        {renderReExecute(reExecuteCount)}
        {renderReEntry(reEntryCount)}

        <QuickAdjustmentsView quickAdjustments={quickAdjustments} />
      </span>
    );
  };

  const renderBasketIntent = (intent, idx) => {
    const {
      identifier: { label },
    } = intent;

    return (
      <div className="transaction_details-items segment" key={`basket-${idx}`}>
        <span className="badge badge-light badge-pill mr-1 border">
          <span className="font-weight-bold">
            Basket
            {' '}
            {' '}
            {label}
          </span>
          &nbsp;
          {_.startCase(intent?.name)}
          &nbsp;
          {intent?.multiplier || ''}
          {' '}
          Multiplier
        </span>
      </div>
    );
  };

  const renderModifyOrderIntent = (intent, idx) => {
    const { type, target: { label: targetLabel } } = intent;

    return (
      <div className="transaction_details-items segment" key={`modify-order-${idx}`}>
        <span className="badge badge-light badge-pill mr-1 border">
          {type}
          {' '}
          Leg
          {' '}
          {targetLabel}
        </span>
      </div>
    );
  };

  const renderIntent = (intent, idx) => {
    const { type, instrument } = intent;

    if (type === INTENT_TYPES.OpenBasket) {
      return renderBasketIntent(intent, idx);
    }

    if (type === INTENT_TYPES.OpenLeg) {
      return (
        <div key={`transaction-config${idx}`} className="mb-1">
          {instrument?.type === SEGMENT_CONFIG.option
            ? renderOptionIntent(intent)
            : renderFutureOrEquityIntent(intent)}
        </div>
      );
    }

    return renderModifyOrderIntent(intent, idx);
  };

  return (
    <>
      {_.isEmpty(intents) && <span className="badge badge-light badge-pill mr-1 border">No Intents</span>}
      {_.map(intents, renderIntent)}
    </>
  );
};

IntentsView.propTypes = propTypes;
IntentsView.defaultProps = defaultProps;

export default IntentsView;
