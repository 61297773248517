import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const propTypes = {
  tradingType: PropTypes.string.isRequired,
  onChangeTradingType: PropTypes.func.isRequired,
};
const defaultProps = {};

const TRADE_TYPES = {
  positional: 'positional',
  intraday: 'intraday'
};

const ExpirySelector = ({ tradingType, onChangeTradingType }) => {
  const { positional, intraday } = TRADE_TYPES;

  return (
    <div className="trade-type mb-4">
      <div className="row">
        <div className="col-md-2">
          <label className="label_style">Trade Type</label>
        </div>
        <div className="col-md-10">
          <ToggleButtonGroup
            bsPrefix="btn-group btn-group-sm btn-group-toggle"
            type="radio"
            name="tradingType"
            defaultValue={tradingType}
            onChange={(newTradingType) => onChangeTradingType(newTradingType)}
          >
            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
              key={intraday}
              value={intraday}
              data-track-category="Expiry Handler"
              data-track-action={intraday}
              data-track-label="Expiry Handler Strategy Type"
            >
              {_.startCase(intraday)}
            </ToggleButton>

            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
              key={positional}
              value={positional}
              data-track-category="Expiry Handler"
              data-track-action={positional}
              data-track-label="Expiry Handler Strategy Type"
            >
              {_.startCase(positional)}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};

ExpirySelector.propTypes = propTypes;
ExpirySelector.defaultProps = defaultProps;

export default ExpirySelector;
