import { RUN_TYPES } from 'common/constants/index';
import moment from 'moment';
import { checkOrderHasIlliquid } from 'ui/run/RunShow/OrderStore/helper';

const BUY = 'buy';
const {
  getConvertedTime, getformattedTime, getCompletedOrders
} = require('./transactions');

export const profitMapBy = (order) => {
  return (
    ((order.traded_quantity || order.quantity) * order.price * (order.transaction_type === 'buy' ? -1 : +1))
  );
};

export const getProfit = (groupedOrders, mapBy) => {
  if (_.isEmpty(groupedOrders)) return 0;

  const totalProfit = _.map(groupedOrders, mapBy);
  const profit = _.reduce(totalProfit, (sum, price) => sum + price);
  return _.round(profit, 2);
};

export const getProfitSplitups = (groupedOrders) => {
  const profitSplitups = _.chain(groupedOrders)
    .groupBy((group) => group.instrument)
    .map((ordersByInstrument, instrument) => ({
      tag: instrument,
      profit: getProfit(ordersByInstrument, profitMapBy)
    }))
    .value();

  return [
    { tag: 'Total Profit', profit: getProfit(groupedOrders, profitMapBy) },
    ...profitSplitups,
  ];
};

export const getDisplayTransactions = (transactions, currentPageIndex, transactionsPerPage) => {
  if (_.isEmpty(transactions)) return [];

  const sortedTransactions = _.orderBy(transactions, 'sortId', 'desc');
  const totalTransactions = _.size(sortedTransactions);
  const startOfRange = transactionsPerPage * currentPageIndex;
  const endOfRange = startOfRange + transactionsPerPage < totalTransactions
    ? startOfRange + transactionsPerPage
    : totalTransactions;
  const transactionRange = _.range(startOfRange, endOfRange);

  return _.map(transactionRange, (idx) => sortedTransactions[idx]);
};

export const getOrderProfit = (entryType, entryPrice, exitPrice, quantity) => {
  if (!entryPrice || !exitPrice) return null;
  // this is mainly for live data updated process eg: dashboard

  const parsedQuantity = parseFloat(quantity);
  const parsedEntryPrice = parseFloat(entryPrice);
  const parsedExitPrice = parseFloat(exitPrice);

  if (entryType === BUY) return (parsedQuantity * parsedExitPrice) - (parsedQuantity * parsedEntryPrice);

  return (parsedQuantity * parsedEntryPrice) - (parsedQuantity * parsedExitPrice);
};

const getOrderProfitMax = (entryOrder, exitOrder) => {
  if (_.isEmpty(entryOrder) || _.isEmpty(exitOrder)) return 0;

  const entryType = _.get(entryOrder, 'transaction_type', null);
  const quantity = _.get(entryOrder, 'quantity', null);
  const entryPrice = Number(_.get(entryOrder, 'price', 0));
  const minPrice = Number(_.get(exitOrder, 'min_price', 0));
  const maxPrice = Number(_.get(exitOrder, 'max_price', 0));

  return entryType === 'sell' ? ((entryPrice * quantity) + (-1 * (quantity * minPrice)))
    : ((-1 * (entryPrice * quantity)) + (quantity * maxPrice));
};

const getOrderProfitMin = (entryOrder, exitOrder) => {
  if (_.isEmpty(entryOrder) || _.isEmpty(exitOrder)) return 0;

  const entryType = _.get(entryOrder, 'transaction_type', null);
  const quantity = _.get(entryOrder, 'quantity', null);
  const entryPrice = Number(_.get(entryOrder, 'price', 0));
  const minPrice = Number(_.get(exitOrder, 'min_price', 0));
  const maxPrice = Number(_.get(exitOrder, 'max_price', 0));

  return entryType === 'sell' ? ((entryPrice * quantity) + (-1 * (quantity * maxPrice)))
    : ((-1 * (entryPrice * quantity)) + (quantity * minPrice));
};

export const getOrderData = (
  orders, runType, orderType, ignoreInCompleteTransactionUntil = null, isMarketplaceAuthorRun = false
) => {
  const stopLossOrder = _.find(orders, (order) => order.is_stop_loss);
  const filteredOrdersBasedOnStopLoss = _.filter(orders, (order) => {
    if (order.is_entry) { return true; }
    if (order === stopLossOrder) { return order.status === 'executed'; }
    if (!stopLossOrder || stopLossOrder.status !== 'executed') { return true; }

    return false;
  });

  const entryOrder = _.find(filteredOrdersBasedOnStopLoss, (
    { is_entry: isEntry }
  ) => _.includes(['true', true], isEntry));
  const exitOrder = _.find(filteredOrdersBasedOnStopLoss, (
    { is_entry: isEntry }
  ) => _.includes(['false', false], isEntry));

  const entryType = _.get(entryOrder, 'transaction_type', null);
  const entryPrice = Number(_.get(entryOrder, 'price', 0));
  const exitPrice = Number(_.get(exitOrder, 'price', 0));
  const quantity = _.get(entryOrder, 'quantity', null);
  const tradingSymbol = _.get(_.head(filteredOrdersBasedOnStopLoss), 'trading_symbol', '');
  const exitType = _.get(exitOrder, 'transaction_type', null);
  const entryOrderStatus = _.get(entryOrder, 'status', '');
  const exitOrderStatus = _.get(exitOrder, 'status', '');
  const isManualExitShow = isMarketplaceAuthorRun ? !exitType
    : !_.includes(['cancelled', 'failed'], entryOrderStatus) && !exitType;
  // bcoz of subscriber run
  // If owner order is failed bcoz of low fund.. subscribers run placed orders.. so have to show squareoff to
  // owner... for users
  const { isCompleted, completedOrders, shouldCalculateUnrealizedProfit } = getCompletedOrders(
    orders, runType, orderType, isMarketplaceAuthorRun
  );

  const entryTime = _.get(entryOrder, 'order_time', null);
  const exitTime = _.get(exitOrder, 'order_time', null);
  const isPrevOrdersIgnored = (ignoreInCompleteTransactionUntil && entryTime)
    ? moment(entryTime).isBefore(ignoreInCompleteTransactionUntil) : false;

  // for profit calculation only...

  const isOrderPresent = _.some(
    completedOrders, (
      { id: individualOrderId }
    ) => individualOrderId === _.get(stopLossOrder, 'id', undefined)
  );

  const profitCalcualtionOrders = !isOrderPresent ? [...completedOrders, stopLossOrder]
    : _.map(completedOrders, (order) => {
      if (stopLossOrder && order.id === stopLossOrder.id && stopLossOrder.status !== 'executed') {
        return { ...stopLossOrder, quantity: 0 };
      }
      return order;
    });

  const profitSplitups = getProfitSplitups(_.compact(profitCalcualtionOrders));
  const orderProfit = isCompleted ? _.get(profitSplitups, '[0].profit', 0) : 0;
  const validCaseForCompletedLeg = isPrevOrdersIgnored || isCompleted;

  const validateCompletedLeg = () => {
    if (_.includes(['cancelled', 'failed'], entryOrderStatus) && exitType) return true;
    if (_.includes(['cancelled', 'failed'], exitOrderStatus) && exitType) return true;

    return validCaseForCompletedLeg;
  };

  return {
    entryOrderId: _.get(entryOrder, 'id', ''),
    jobId: _.get(entryOrder, 'orderable_id', ''),
    entryType,
    exitType,
    entryTime: getformattedTime(entryTime),
    exitTime: getformattedTime(exitTime),
    orderProfit: _.round(orderProfit, 2),
    quantity,
    entryPrice,
    exitPrice,
    hasIlliquid: checkOrderHasIlliquid(filteredOrdersBasedOnStopLoss),
    tradingSymbol,
    isManualExitShow: isManualExitShow && !isPrevOrdersIgnored,
    isCompleted: validateCompletedLeg(),
    completedOrders,
    orderProfitMax: isManualExitShow ? 0 : getOrderProfitMax(entryOrder, exitOrder),
    orderProfitMin: isManualExitShow ? 0 : getOrderProfitMin(entryOrder, exitOrder),
    shouldCalculateUnrealizedProfit: shouldCalculateUnrealizedProfit && !isPrevOrdersIgnored,
    entryOrderStatus,
    exitOrderStatus,
    orders,
    entryOrder: entryOrder || {},
    exitOrder: exitOrder || {}
  };
};

export const getPlChartTimeStamp = (time) => {
  let timeStamp = moment(getConvertedTime(time)).format('ddd MMM DD YYYY HH:mm:ss');
  timeStamp += ' GMT+0530 (India Standard Time)';

  return timeStamp;
};

export const groupByTransactionFormat = (orderItem) => {
  return `${orderItem.option_config_index}-${orderItem.adjustment_config_index}-${orderItem.trading_symbol}`;
};

const configuredByGroupedOrders = (
  groupedOrders,
  runType,
  orderType,
  ignoreInCompleteTransactionUntil,
  isMarketplaceAuthorRun = false
) => {
  const groupedByTradingSymbol = _.groupBy(groupedOrders, groupByTransactionFormat);
  const pairedOrders = _.map(groupedByTradingSymbol, (groupedBy) => getOrderData(
    groupedBy, runType, orderType, ignoreInCompleteTransactionUntil, isMarketplaceAuthorRun
  ));
  const filteredValidOrders = _.flattenDeep(_.map(pairedOrders, ({ isCompleted, completedOrders }) => {
    if (isCompleted) return completedOrders;
    return [];
  }));

  const instrumentGroupIndex = Number(_.get(groupedOrders, '[0].instrument_group_index'));
  const transactionNumber = Number(_.get(groupedOrders, '[0].transaction_id'));
  const instruments = _.chain(groupedOrders).map('instrument').uniq().value();
  const sortedValidOrders = _.sortBy(filteredValidOrders, 'order_time');
  const isShowSquareOff = _.some(pairedOrders, ({ isManualExitShow }) => isManualExitShow);
  const isComplete = !isShowSquareOff && _.some(pairedOrders, ({ isCompleted }) => isCompleted);
  const groupedOrderSorted = _.sortBy(groupedOrders, 'order_time');
  const transactionAndRunId = _.get(groupedOrders, '[0].transaction_and_run_id');
  const transactionOpenTime = _.get(groupedOrderSorted, [0, 'order_time'], '');
  const overallMaxProfit = isComplete ? _.sumBy(pairedOrders, ({ orderProfitMax }) => orderProfitMax) : 0;
  const overallMinProfit = isComplete ? _.sumBy(pairedOrders, ({ orderProfitMin }) => orderProfitMin) : 0;
  const overallProfit = _.sumBy(pairedOrders, ({ orderProfit }) => orderProfit);
  const runId = _.get(groupedOrders, '[0].run_id', '');

  return {
    isComplete,
    groupedOrderSorted, // with StopLoss
    groupedByTradingSymbolSize: _.size(groupedByTradingSymbol),
    pairedOrders,
    instruments,
    isShowSquareOff,
    orders: sortedValidOrders, // without stopLoss
    overallProfit,
    overallMaxProfit,
    overallMinProfit,
    instrumentGroupIndex,
    transactionNumber,
    transactionOpenTime,
    transactionCloseTime: _.get(_.last(sortedValidOrders), 'order_time'),
    isIlliquidTransaction: checkOrderHasIlliquid(sortedValidOrders),
    transactionAndRunId,
    runId
  };
};

export const getFormattedTransactions = (
  orders, runType, orderType, ignoreInCompleteTransactionUntil, isMarketplaceAuthorRun
) => {
  let previousCumulativeProfit = 0;

  return _.chain(orders)
    .map((order) => {
      const orderTimeMoment = moment(order.order_time);
      // FYI, OrderTime for 2021-03-01T09:23:59.000+05:30 -> Add 1 seconds 2021-03-01T09:24:00.0000+05:30
      if (orderTimeMoment.seconds() === 59) {
        const modifiedSignalTime = orderTimeMoment.add(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        const modifiedOrder = { ...order, order_time: modifiedSignalTime };
        // order_time and signal_time both are same.. Backtest have order_time.. Live have signal_time
        // So Common purpose used order_time.. but somewhere showing signal_time also..
        // that also modified below

        return runType === RUN_TYPES.live
          ? { ...modifiedOrder, signal_time: modifiedSignalTime } : modifiedOrder;
      }

      return order;
    })
    .groupBy((orderItem) => `${orderItem.instrument_group_index}-${orderItem.transaction_and_run_id}`)
    .map((groupedOrders) => {
      return configuredByGroupedOrders(
        groupedOrders, runType, orderType, ignoreInCompleteTransactionUntil, isMarketplaceAuthorRun
      );
    })
    .sortBy((groupedOrder) => { return _.get(groupedOrder, 'transactionOpenTime'); })
    .map((groupedOrder, sortId) => {
      const { isComplete, overallProfit, transactionCloseTime } = groupedOrder;

      const timestamp = getPlChartTimeStamp(transactionCloseTime);
      const cumulativeProfit = isComplete ? {
        value: previousCumulativeProfit + overallProfit,
        timestamp: new Date(timestamp)
      } : {};

      previousCumulativeProfit = _.get(cumulativeProfit, 'value', previousCumulativeProfit);

      return { ...groupedOrder, sortId, cumulativeProfit };
    })
    .value();
};
