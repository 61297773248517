import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';
import _ from 'lodash';
import { timeBasedSignalPropTypes } from 'common/propTypes';
import TimeSelector from 'common/components/TimeSelector';
import { getTimeValidatorsV2 } from 'common/utils/validators';
import ErrorMessage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  timeBasedSignal: timeBasedSignalPropTypes.isRequired,
  onChangeTimeBasedSignal: PropTypes.func.isRequired,
};

const defaultProps = {};

const Intraday = (props) => {
  const {
    timeBasedSignal,
    timeBasedSignal: { entryTime, exitTime },
    onChangeTimeBasedSignal,
  } = props;

  const { errors, control } = useFormContext();
  const [timePickerName] = useState(_.uniqueId('intradayTimePicker-'));

  const onTimeBasedSignalChange = (newConfig) => {
    onChangeTimeBasedSignal({ ...timeBasedSignal, ...newConfig });
  };

  const startTimeStyle = classNames({
    invalid: !_.isEmpty(errors[`${timePickerName}-start-time`]),
  });
  const endTimeStyle = classNames({
    invalid: !_.isEmpty(errors[`${timePickerName}-end-time`]),
  });

  // todo: Add the error message for the time picker
  const startTimeError = _.get(errors, `${timePickerName}-start-time.message`, '');
  const endTimeError = _.get(errors, `${timePickerName}-end-time.message`, '');

  return (
    <div className="trade-during mb-4">
      <div className="row">
        <div className="col-md-2">
          <label className="label_style">Trade During</label>
        </div>
        <div className="col-md-10">
          <div className="d-flex align-items-center">
            <ErrorMessage message={startTimeError || endTimeError}>
              <Controller
                render={({ onChange }) => (
                  <TimeSelector
                    style={startTimeStyle}
                    onTimeChange={(newTime) => {
                      onTimeBasedSignalChange({ entryTime: newTime });
                      onChange(newTime);
                    }}
                    selectedTime={entryTime}
                  />
                )}
                control={control}
                defaultValue={entryTime}
                name={`${timePickerName}-start-time`}
                rules={getTimeValidatorsV2()}
              />
              <span className="heifhan">-</span>
              <Controller
                render={({ onChange }) => (
                  <TimeSelector
                    style={endTimeStyle}
                    onTimeChange={(newTime) => {
                      onTimeBasedSignalChange({ exitTime: newTime });
                      onChange(newTime);
                    }}
                    selectedTime={exitTime}
                    minTime={entryTime}
                  />
                )}
                control={control}
                defaultValue={exitTime}
                name={`${timePickerName}-end-time`}
                rules={getTimeValidatorsV2()}
              />
            </ErrorMessage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intraday;

Intraday.propTypes = propTypes;
Intraday.defaultProps = defaultProps;
