import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  isPremiumError: PropTypes.bool,
};
const defaultProps = {
  message: '',
  children: null,
  isPremiumError: false,
};

const ErrorMesssage = ({ message, children, isPremiumError }) => {
  return (
    <>
      {children}
      {message
        && (
        <span className="error text-danger tx-10">
          {message}
          {isPremiumError && (
            <img src="/v2Crown.png" className="v2-crown-image" alt="" />
          )}
        </span>
        )}
    </>
  );
};

export default ErrorMesssage;

ErrorMesssage.propTypes = propTypes;
ErrorMesssage.defaultProps = defaultProps;
