import { defaultUiBehaviourFlags } from 'v2/modules/withRunForm/configs/index';
import { setPartialQuantConfig } from 'v2/modules/withRunForm/redux/actions';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RUN_TYPES } from 'v2/common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import { onChangeUiBehaviourFlags } from '../../events/uiBehaviourFlags';

const propTypes = {};
const defaultProps = {};

const FeatureEnablements = () => {
  const uiBehaviourFlags = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiBehaviourFlags')
  ) || defaultUiBehaviourFlags;
  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));

  const {
    isReEntry, isReExecute, isTrailingStopLoss, isWaitAndTrade
  } = uiBehaviourFlags;
  const dispatch = useDispatch();

  const onUpdateUiBehaviourFlags = useCallback((updateValues) => {
    const newUiBehaviourFlags = { ...uiBehaviourFlags, ...updateValues };
    dispatch(setPartialQuantConfig(onChangeUiBehaviourFlags(
      { cases, uiBehaviourFlags: newUiBehaviourFlags }
    )));
  }, [cases, dispatch, uiBehaviourFlags]);

  const isShowWaitAndTrade = useMemo(() => (
    runType === RUN_TYPES.live
      ? _.get(window, 'userPermissions.waitAndTrade.isEnabled', false)
      : _.get(window, 'userPermissions.waitAndTradeForBacktest.isEnabled', false)
  ), [runType]);

  const reEntryContent = (
    <div>
      No re-entry happens if the trailing stop loss or move to cost is already triggered.
    </div>
  );

  const reExecuteContent = (
    <div>
      No re-execute happens if the trailing stop loss or move to cost is already triggered.
    </div>
  );

  const featureConfigs = useMemo(() => [
    {
      label: 'Trailing Stop Loss',
      isPermissionToShow: _.get(window, 'userPermissions.trailingStopLoss.isEnabled', false),
      isChecked: isTrailingStopLoss,
      id: 'isTrailingStopLoss',
      updateValues: { isTrailingStopLoss: !isTrailingStopLoss },
      isdisabled: false,
    },
    {
      label: 'Re-Entry',
      isPermissionToShow: _.get(window, 'userPermissions.reEntry.isEnabled', false),
      isChecked: isReEntry,
      id: 'isReEntry',
      updateValues: { isReEntry: !isReEntry },
      isdisabled: false,
      infoIcon: (
        <HoverMessage message={reEntryContent}>
          <span className="material-icons-outlined info-icon mr-1">
            info
          </span>
        </HoverMessage>
      )
    },
    {
      label: 'Re-Execute',
      isPermissionToShow: _.get(window, 'userPermissions.reExecute.isEnabled', false),
      isChecked: isReExecute,
      id: 'isReExecute',
      updateValues: { isReExecute: !isReExecute },
      isdisabled: false,
      infoIcon: (
        <HoverMessage message={reExecuteContent}>
          <span className="material-icons-outlined info-icon mr-1">
            info
          </span>
        </HoverMessage>
      )
    },
    {
      label: 'Wait and Trade',
      isPermissionToShow: isShowWaitAndTrade,
      isChecked: isWaitAndTrade,
      id: 'isWaitAndTrade',
      updateValues: { isWaitAndTrade: !isWaitAndTrade },
      isdisabled: false,
    }
  ], [isReEntry, isReExecute, isShowWaitAndTrade,
    isTrailingStopLoss, isWaitAndTrade, reEntryContent, reExecuteContent
  ]);

  const renderFeatureConfig = useCallback(({
    label, isPermissionToShow, isChecked, id, isdisabled, updateValues, infoIcon
  }) => {
    if (!isPermissionToShow) return null;

    return (
      <div key={id} className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input track"
            type="checkbox"
            data-track-category="Advanced Features Enablement"
            data-track-action={label}
            data-track-label={label}
            disabled={isdisabled}
            id={id}
            onClick={() => { }}
            onChange={() => onUpdateUiBehaviourFlags(updateValues)}
            checked={isChecked}
          />
          <label
            className="form-check-label tx-13"
            htmlFor={id}
            disabled={isdisabled}
          >
            {label}
          </label>
        </div>
        {infoIcon && <>{infoIcon}</>}
      </div>
    );
  }, [onUpdateUiBehaviourFlags]);

  return (
    <div className="features-enablement-details py-2 px-4">
      <div className="d-flex align-items-start align-items-md-center gap-20">
        <div className="font-weight-medium tx-15">Features Enablement:</div>
        <div className="d-flex justify-content align-items-center gap-10 flex-wrap">
          {_.map(featureConfigs, renderFeatureConfig)}
        </div>
      </div>
    </div>
  );
};

FeatureEnablements.propTypes = propTypes;
FeatureEnablements.defaultProps = defaultProps;

export default FeatureEnablements;
