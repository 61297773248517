import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { validateBrokerUser } from 'common/api/user';
import { availableBrokers } from 'common/brokerConfigs/config';
import ReactHtmlParser from 'react-html-parser';
import ErrorMesssage from 'common/components/ErrorMesssage';
import SecretKeyAuthentication from '../SecretKeyAuthentication';
import {
  getBrokerImage, getBrokerNotes, reDirectPage, simpleLoginBrokers
} from '../config';
import BrokerSimpleLogin from './BrokerSimpleLogin';
import BrokerContent from '../BrokerContent/index';

const propTypes = { onCloseModal: PropTypes.func.isRequired, broker: PropTypes.string.isRequired };

const defaultProps = {};

const btnTitle = 'Login';
const errorMessage = '*Please give the valid id';

const BrokerIdAuthentication = ({ onCloseModal, broker }) => {
  const [isOpenBrokerIdAuthenticationModal, setIsOpenBrokerIdAuthenticationModal] = useState(true);
  const [brokerDetails, setBrokerDetails] = useState({ brokerUserId: '' });
  const [isValidUser, setIsUserValid] = useState(true);
  const [isOpenSecretKeyAuthenticationModal, setIsOpenSecretKeyAuthenticationModal] = useState(false);
  const [setupGuideOnly, setSetupGuideOnly] = useState(false);
  const { brokerUserId } = brokerDetails;

  const isSimpleLoginEnabled = _.includes(simpleLoginBrokers, broker);
  const textFocus = useRef(null);
  const currentBrokerName = `${availableBrokers[broker] || ''}`;

  const navigateBrokerModal = () => {
    setIsOpenSecretKeyAuthenticationModal(true);
    setIsOpenBrokerIdAuthenticationModal(false);
  };

  const onValidate = (isChangeApiDetails) => {
    if (broker === 'upstox' && isChangeApiDetails) {
      setIsOpenSecretKeyAuthenticationModal(false);
      setIsOpenBrokerIdAuthenticationModal(false);
      setSetupGuideOnly(true);
      return null;
    }

    if (brokerUserId !== '') {
      if (isChangeApiDetails) return navigateBrokerModal(); // change Api details.. so directly
      //  change the modal no need to make call.. but brokerUserId validation is mandatory...

      validateBrokerUser({ broker_user_id: brokerUserId, broker }).then((result) => {
        const { status, broker_api_credentials: brokerApiCredientals } = result;
        if (!status) {
          navigateBrokerModal();
          return;
        }

        const { api_key: apiKey, api_secret: apiSecret } = brokerApiCredientals;
        const reDirectData = {
          api_key: apiKey, api_secret: apiSecret, broker_user_id: brokerUserId
        };
        reDirectPage(reDirectData, broker);
      });

      return null;
    }

    return setIsUserValid(false);
  };

  const modalProps = !isSimpleLoginEnabled ? {
    onSave: () => onValidate(),
    onClose: onCloseModal,
    title: `${currentBrokerName} User Authentication` || '',
    size: 'md',
    okBtnTitle: btnTitle,
    onSaveid: `btn-${broker}`
  } : {
    onClose: onCloseModal,
    title: `${currentBrokerName} User Authentication` || '',
    size: 'md',
    onSaveid: `btn-${broker}`
  };

  useEffect(() => {
    if (!isSimpleLoginEnabled) textFocus.current.focus();
  }, []);

  const renderModalContent = () => {
    if (isSimpleLoginEnabled) {
      return (
        <BrokerSimpleLogin
          currentBrokerName={currentBrokerName}
          broker={broker}
          handleChange={(value) => setBrokerDetails({ brokerUserId: value })}
          handleClick={() => onValidate()}
          brokerUserId={brokerUserId}
          isValidUser={isValidUser}
          errorMessage={errorMessage}
        />
      );
    }

    const labelContent = () => {
      let msg = `${currentBrokerName} User Id`;
      if (broker === 'paytm') msg = `Registered mobile on ${currentBrokerName}`;

      return msg;
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <label>
            {labelContent()}
          </label>
        </div>
        <input
          className="form-control"
          type="text"
          ref={textFocus}
          id={`${broker}-client-id`}
          value={brokerUserId}
          onChange={(event) => {
            setBrokerDetails({ brokerUserId: event.target.value });
          }}
        />
        <ErrorMesssage error={{ message: isValidUser ? '' : errorMessage }} />
        {ReactHtmlParser(getBrokerNotes(broker))}
      </>
    );
  };

  if (isOpenBrokerIdAuthenticationModal) {
    return (
      <Modal {...modalProps}>
        <>
          {ReactHtmlParser(getBrokerImage(broker))}
          {renderModalContent()}
          <button
            className="btn btn-link btn-sm text-nowrap"
            type="button"
            onClick={() => onValidate(true)}
          >
            {broker === 'upstox'
              ? 'One time setup required. - How to Connect Upstox to Quantman ?'
              : 'Change api details'}
          </button>
        </>
      </Modal>
    );
  }

  if (isOpenSecretKeyAuthenticationModal) {
    return (
      <SecretKeyAuthentication onCloseModal={onCloseModal} brokerUserId={brokerUserId} broker={broker} />
    );
  }

  if (setupGuideOnly) {
    return (
      <Modal
        onClose={onCloseModal}
        title={`${availableBrokers[broker]} Api Credentials` || ''}
        size="lg"
        okBtnTitle={btnTitle}
      >
        <BrokerContent broker={broker} />
      </Modal>
    );
  }

  return <></>;
};

export default BrokerIdAuthentication;

BrokerIdAuthentication.propTypes = propTypes;
BrokerIdAuthentication.defaultProps = defaultProps;
