import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DOMAINS } from 'common/constants';
import { getSessionStorage, setSessionStorage } from 'common/utils/session';
import {
  brokerStructuredData,
  // loginPageTopBrokers
} from './config';
import AngelOneLogin from './specificDomainLoginPages/AngelOneLogin';
import SignIn from './SignIn';
import SignUp from './SignUp';
import AuthenticationModal from './AuthenticationModal/index';
import AlicBlueLogin from './specificDomainLoginPages/AlicBlueLogin';
import PaytmLogin from './specificDomainLoginPages/PaytmLogin';
import DhanLogin from './specificDomainLoginPages/DhanLogin';
import GoodWillLogin from './specificDomainLoginPages/GoodWillLogin';
import IciciDirectLogin from './specificDomainLoginPages/IciciDirectLogin';
import NuvamaLogin from './specificDomainLoginPages/NuvamaLogin';
import BigulLogin from './specificDomainLoginPages/bigulLogin';
import AsthaTradeLogin from './specificDomainLoginPages/AsthaTradeLogin';
import EnrichMoneyLogin from './specificDomainLoginPages/EnrichMoneyLogin';
import BrokerAccountLoginModal from './BrokerAccountLoginModal';
import KotakNeoLogin from './specificDomainLoginPages/KotakNeoLogin';
import SharekhanLogin from './specificDomainLoginPages/SharekhanLogin';
import BrokerSelectionDropDown from './BrokerSelectionDropDown';
import PhilipCapitalLogin from './specificDomainLoginPages/PhilipCapitalLogin';
import SamcoLogin from './specificDomainLoginPages/samcoLogin';
import UpstoxLogin from './specificDomainLoginPages/UpstoxLogin';
import InvestRightLogin from './specificDomainLoginPages/investRightLogin';
import HdfcSky from './specificDomainLoginPages/hdfcSky';

const propTypes = {
  mode: PropTypes.string,
  // TODO: is this the best way?? should we set it ApplicationDomain as global var for all
  // components to consume without sending as props.
  supportedBrokers: PropTypes.arrayOf(PropTypes.string),
  allowAlternateLoginMode: PropTypes.bool,
  allowGoodwillDealer: PropTypes.bool
};
const defaultProps = {
  mode: 'signIn',
  supportedBrokers: null,
  allowAlternateLoginMode: true,
  allowGoodwillDealer: false
};

const ENTRY_MODES = [
  { label: 'Login', value: 'signIn' },
  { label: 'Register', value: 'signUp' }
];

const ENTRY_COMPONENTS = { signIn: SignIn, signUp: SignUp };
const CURRENT_MODE = 'user_auth_current_mode';
// const MAX_BROKERS_TO_SHOW = 11;

const UserAuth = (props) => {
  const { supportedBrokers, allowAlternateLoginMode } = props;
  const defaultMode = getSessionStorage(CURRENT_MODE);
  const [currentEntryMode, setCurrentEntryMode] = useState(defaultMode || '');
  const CurrentEntryComponent = ENTRY_COMPONENTS[currentEntryMode];
  const [currentBroker, setCurrentBroker] = useState('');
  const oneIndentation = ' ';
  const [selectedBroker, setSelectedBroker] = useState('');
  const [showBrokersLoginModal, setShowBrokersLoginModal] = useState(false);

  useEffect(() => {
    return () => { setSessionStorage(CURRENT_MODE, ''); };
  }, []);

  const onHandleCurrentEntryMode = (value) => {
    if (currentEntryMode === value) {
      setCurrentEntryMode('');
      setSessionStorage(CURRENT_MODE, '');
    } else {
      setCurrentEntryMode(value);
      setSessionStorage(CURRENT_MODE, value);
    }
  };

  const renderEntryTabItem = ({ label, value }) => {
    const buttonClassName = classNames('btn btn-block badge-pill btn-outline-primary', {
      'mt-0 active': (value === currentEntryMode)
    });

    return (
      <button
        type="button"
        key={value}
        className={buttonClassName}
        id={value}
        onClick={() => { onHandleCurrentEntryMode(value); }}
      >
        {label}
      </button>
    );
  };
  const currentComponentDivClassName = classNames('exp-login', { 'exp-login-open': (CurrentEntryComponent) });

  const renderAlternateLoginContent = () => {
    if (!allowAlternateLoginMode) {
      return null;
    }

    return (
      <>
        <div className="d-flex align-items-center mb-2 mt-4">
          <div className="border-bottom w-100" />
          <span className="px-2 text-muted font-weight-bold text-muted">OR</span>
          <div className="border-bottom w-100" />
        </div>

        <div className="text-center">
          Continue with
          {oneIndentation}
          <button
            type="button"
            className="btn btn-link font-weight-bold px-0"
            onClick={() => { onHandleCurrentEntryMode('signIn'); }}
          >
            <u>Email</u>
          </button>
        </div>
        {currentEntryMode && (
          <div className="d-flex align-items-center gap-20">
            {_.map(ENTRY_MODES, renderEntryTabItem)}
          </div>
        )}

        <div className={currentComponentDivClassName}>
          {currentEntryMode && <div className="expandable"><CurrentEntryComponent {...props} /></div>}
        </div>
      </>
    );
  };

  const sanitizedBrokerStructuredData = useMemo(() => _.filter(brokerStructuredData, (broker) => {
    return (
      (_.isEmpty(supportedBrokers) || _.includes(supportedBrokers, _.get(broker, 'brokerConfig.id', '')))
      && broker.isShow
    );
  }, [brokerStructuredData]));

  // const renderBroker = (broker, idx) => {
  //   if (idx > (MAX_BROKERS_TO_SHOW - 1)) { return null; }

  //   const {
  //     brokerConfig, brokerClassName, imagePath, imageClassName
  //   } = broker;
  //   const href = _.get(broker, 'href', '');
  //   const name = _.get(broker, 'name', '');

  //   return (
  //     <button
  //       type="button"
  //       className={brokerClassName}
  //       key={idx}
  //       id={`${brokerConfig.id}-user-auth`}
  //       onClick={() => {
  //         if (href) window.location.href = href;
  //         else setCurrentBroker(brokerConfig.id);
  //       }}
  //     >
  //       {imagePath && <img src={imagePath} alt={brokerConfig.name} className={imageClassName} />}
  //       {name && <span>{name}</span>}
  //     </button>
  //   );
  // };

  // const loginPageBrokersConfig = () => {
  //   const loginPageBrokers = _.chain(sanitizedBrokerStructuredData)
  //     .filter((broker) => _.includes(loginPageTopBrokers, _.get(broker, 'brokerConfig.id', '')))
  //     .sortBy((broker) => _.indexOf(loginPageTopBrokers, _.get(broker, 'brokerConfig.id', '')))
  //     .value();

  //   if (!(loginPageBrokers.length < MAX_BROKERS_TO_SHOW)) {
  //     return loginPageBrokers;
  //   }

  //   return _.chain(sanitizedBrokerStructuredData)
  //     .sortBy((broker) => broker.sortOrder)
  //     .filter((broker) => !_.includes(loginPageTopBrokers, _.get(broker, 'brokerConfig.id', '')))
  //     .slice(0, (MAX_BROKERS_TO_SHOW - loginPageBrokers.length))
  //     .thru((value) => [...loginPageBrokers, ...value])
  //     .value();
  // };

  // const loginPageBrokers = loginPageBrokersConfig();
  // const moreBrokerCount = sanitizedBrokerStructuredData.length - loginPageBrokers.length;

  const selectedBrokerConfig = _.find(sanitizedBrokerStructuredData,
    (broker) => _.get(broker, 'brokerConfig.id', '') === selectedBroker);

  return (
    <div className="login-panel-wrapper user-auth-component broker-new-login">
      <div className="login-panel">
        <div className="px-4 py-4">
          <h5 className="text-center mb-4 font-weight-semi">Login with your broker</h5>
          <div className="broker-login-container">
            {/* {_.map(loginPageBrokers, renderBroker)}

            {(moreBrokerCount > 0) && (
              <button
                type="button"
                className="btn btn-link font-weight-bold px-0"
                id="more-broker"
                onClick={() => { setShowBrokersLoginModal(true); }}
              >
                <u>
                  {`+${moreBrokerCount}`}
                  {' '}
                  More brokers
                </u>
              </button>
            )} */}
            {/* <div className="text-center">
              Continue with
              {oneIndentation}
              <button
                type="button"
                className="btn btn-link font-weight-bold px-0"
                onClick={() => { onHandleCurrentEntryMode('signIn'); }}
              >
                <u>Broker</u>
              </button>
            </div> */}
            {showBrokersLoginModal && (
              <BrokerAccountLoginModal
                brokersConfig={sanitizedBrokerStructuredData}
                onCloseModal={() => { setShowBrokersLoginModal(false); }}
                onSetBroker={(brokerId) => {
                  setShowBrokersLoginModal(false);
                  setCurrentBroker(brokerId);
                }}
              />
            )}
            <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="broker-login-box">
              <div className="text-center mb-3 mt-2">
                Continue with
                {oneIndentation}
                <b>Broker</b>
                {' '}
                select here
              </div>
              <BrokerSelectionDropDown
                selectedBroker={selectedBroker}
                brokerStructuredData={sanitizedBrokerStructuredData}
                onHandleSelectedBroker={setSelectedBroker}
              />
            </div>

            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={() => {
                if (selectedBrokerConfig?.href) window.location.href = selectedBrokerConfig?.href;
                else setCurrentBroker(selectedBroker);
              }}
            >
              Login with broker
            </button>
          </div>
          {renderAlternateLoginContent()}
        </div>
      </div>
    </div>
  );
};

UserAuth.propTypes = propTypes;
UserAuth.defaultProps = defaultProps;

export default (props) => {
  const { currentDomain } = window;
  switch (currentDomain) {
    case DOMAINS.ANGEL_ONE:
      return <AngelOneLogin />;
    case DOMAINS.ALICE_BLUE:
      return <AlicBlueLogin />;
    case DOMAINS.ICICI_DIRECT:
      return <IciciDirectLogin />;
    case DOMAINS.GOODWILL:
      return <GoodWillLogin {...props} />;
    case DOMAINS.PAYTM_MONEY:
      return <PaytmLogin />;
    case DOMAINS.BIGUL:
      return <BigulLogin />;
    case DOMAINS.DHAN:
      return <DhanLogin />;
    case DOMAINS.NUVAMA:
      return <NuvamaLogin />;
    case DOMAINS.ASTHA_TRADE:
    case DOMAINS.RUPEEZY:
      return <AsthaTradeLogin />;
    case DOMAINS.ENRICH_MONEY:
      return <EnrichMoneyLogin />;
    case DOMAINS.KOTAK_NEO:
      return <KotakNeoLogin />;
    case DOMAINS.SHAREKHAN:
      return <SharekhanLogin />;
    case DOMAINS.PHILLIP_CAPITAL:
      return <PhilipCapitalLogin />;
    case DOMAINS.SAMCO:
      return <SamcoLogin />;
    case DOMAINS.UPSTOX:
      return <UpstoxLogin />;
    case DOMAINS.INVESTRIGHT:
      return <InvestRightLogin />;
    case DOMAINS.HDFCSKY:
      return <HdfcSky />;
    default:
      return <UserAuth {...props} />;
  }
};
