import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  label: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
const defaultProps = {
  label: 'Quantity',
};

const Quantity = ({
  quantity, onChangeQuantity, label, control, errors
}) => {
  const [quantityName] = useState(_.uniqueId('optionsQuantity-'));
  const inputBoxStyle = classNames('form-control form-control-sm', { 'is-invalid': !!errors[quantityName] });

  const validator = {
    validate: (value) => {
      if (!value) {
        return `${label} should be included`;
      }

      return true;
    }
  };

  return (
    <div className="total-lot">
      <ErrorMesssage message={errors[quantityName]?.message || ''}>
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              type="number"
              ref={ref}
              className={inputBoxStyle}
              name={name}
              value={quantity === undefined ? '' : quantity.toString()}
              min={1}
              onChange={(event) => {
                onChangeQuantity(event.target.value);
                onChange(event.target.value);
              }}
            />
          )}
          name={quantityName}
          defaultValue={quantity}
          control={control}
          rules={validator}
        />
      </ErrorMesssage>
    </div>
  );
};

Quantity.propTypes = propTypes;
Quantity.defaultProps = defaultProps;

export default memo(Quantity);
