import React, { useEffect, useState } from 'react';
import { getUrlParams } from 'common/utils/urlUtils';
import { getNumerousRuns } from 'common/api/run';
import OrderStore from 'ui/run/RunShow/OrderStore/index';
import RunDetail from 'ui/run/RunShow/components/RunDetail/index';
import RunSummary from 'modules/RunSummary/index';
import { RUN_TYPES } from 'common/constants/index';
import TransactionDetail from 'ui/run/RunShow/components/TransactionDetail/index';
import SelectRuns from './SelectRuns';
import OptimizeBy from './OptimizeBy';

const propTypes = {};
const defaultProps = {};

const NumerousRuns = () => {
  const urlParams = getUrlParams();
  const initialRunIds = JSON.parse(_.get(urlParams, 'runids', '[]'));
  const [runIds] = useState(initialRunIds);
  const [checkedRunIds, setCheckedRunIds] = useState(runIds);
  const [optimizeBy, setOptimizeBy] = useState('');
  const [runsWithJobs, setRunsWithJobs] = useState([]);

  useEffect(() => {
    getNumerousRuns({ run_ids: runIds })
      .then((result) => {
        const { runs: numerousRuns } = result;
        setRunsWithJobs(numerousRuns);
      });
  }, [runIds]);

  const handleCheckboxChange = (runId) => {
    setOptimizeBy('');
    setCheckedRunIds((prevCheckedRunIds) => (prevCheckedRunIds.includes(runId)
      ? prevCheckedRunIds.filter((id) => id !== runId)
      : [...prevCheckedRunIds, runId]));
  };

  const handleOptimizedRunIds = (optimizedRunIds) => {
    if (checkedRunIds.length !== optimizedRunIds.length) {
      setCheckedRunIds(optimizedRunIds);
    }
  };

  const handleOptimizeBy = (value) => {
    if (_.isEmpty(value)) setCheckedRunIds(runIds);
    setOptimizeBy(value);
  };

  const onClickRefineBtn = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('runids', JSON.stringify(checkedRunIds));
    window
      .open(url.toString(), '_blank')
      .focus();

    return null;
  };

  const filterCheckedJobs = () => {
    return _.map(checkedRunIds, (runId) => {
      return runsWithJobs[runId].job_ids;
    });
  };

  const renderErrorMessages = () => {
    return (
      <div className="container my-3">
        <div className="alert alert-info">No runs found. Please select runs to view results</div>
      </div>
    );
  };

  const allRuns = _.map(runsWithJobs, (r) => r.run);

  const run = {
    ..._.head(allRuns),
    start_date: _.minBy(allRuns, (r) => r.start_date)?.start_date,
    end_date: _.maxBy(allRuns, (r) => r.end_date)?.end_date,
  };

  const { order_type: orderType, run_type: runType } = run;

  const isRunsAvailable = !(_.isEmpty(checkedRunIds) || _.isEmpty(allRuns));

  const renderOptimizeBy = () => {
    return (
      <OptimizeBy
        optimizeBy={optimizeBy}
        handleOptimizeBy={handleOptimizeBy}
        onClickRefineBtn={() => onClickRefineBtn()}
      />
    );
  };
  return (
    <>
      <SelectRuns
        runIds={runIds}
        runs={allRuns}
        checkedRunIds={checkedRunIds}
        handleCheckboxChange={handleCheckboxChange}
        rowFunc={renderOptimizeBy}
      />
      {!isRunsAvailable && renderErrorMessages()}
      {isRunsAvailable && (
        <OrderStore
          runIds={checkedRunIds}
          jobIds={filterCheckedJobs()}
          orderType={orderType}
          runType={runType}
          run={run}
          setCheckedRunIds={handleOptimizedRunIds}
          optimizeBy={optimizeBy}
        >
          <>
            <RunDetail run={run} />
            {runType === RUN_TYPES.historic && <RunSummary />}
            <TransactionDetail run={run} />
          </>
        </OrderStore>
      )}
    </>
  );
};

NumerousRuns.propTypes = propTypes;
NumerousRuns.defaultProps = defaultProps;

export default NumerousRuns;
