import { useContext, useEffect } from 'react';
import { IntentsContext } from '../../hoc/withIntentsContext';

const isPermissionToAddManualAdjustment = _.get(window, 'userPermissions.adjustments.isEnabled', false)
  || window?.isAdmin || false;

export const useQuickAdjustments = (intentId, quickAdjustments) => {
  const {
    adjustmentIndex,
    caseIndex,
    clearErrors,
    errors,
    setError,
  } = useContext(IntentsContext);
  const fieldId = `${caseIndex}-${adjustmentIndex}-${intentId}`;

  useEffect(() => {
    if (!isPermissionToAddManualAdjustment && quickAdjustments.length > 0) {
      setError(fieldId, {
        isPremiumError: true,
        forceScrollTo: `#${fieldId}`,
        message: 'Please purchase a premium plan to use Triggers.',
        caseIndex
      });
    } else {
      clearErrors(fieldId);
    }
    return () => {
      clearErrors(fieldId);
    };
  }, [caseIndex, adjustmentIndex, intentId, quickAdjustments, setError, fieldId, clearErrors]);

  return {
    error: errors[fieldId] || {},
    fieldId
  };
};
