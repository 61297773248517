import { getAlteredEmptyQuantConfig } from 'modules/QuantBuilder/config/index';
import {
  current1minuteCandle, CURRENT_ONE_MINUTE_CANDLE_NAME, getStopGainPercent, getStopGainPrice
} from '../../config';

export const SUPER_TREND = 'superTrend';

export const superTrendDefaultConfig = {
  comparisonType: 'crossesAbove',
  period: 10,
  multiplier: 2,
  interval: '1 minutes',
  actiontype: 'buy',
  optionType: 'CE',
  strikeIndex: 0,
  stopLoss: 0,
  stopGain: 0,
  quantity: 1,
  stopLossType: 'percent',
  stopGainType: 'percent',
};

export const quantConfigConversionSt = (config) => {
  const {
    comparisonType, interval, period, instruments, multiplier,
    actiontype, optionType, stopGain, stopLoss, strikeIndex, quantity,
    stopLossType,
    stopGainType, segment
  } = config;

  const pipeConfig = [
    {
      type: SUPER_TREND,
      name: `${SUPER_TREND}${period}`,
      config: {
        noOfCandles: Number(period),
        candleInterval: interval,
        multiplier: Number(multiplier),
        valuePaths: ['item0.month1Price'],
      },
    },
    current1minuteCandle()
  ];

  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs, pipeConfigs } = emptyConfig;
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const setOptionConfig = {
    ...optionConfigObj,
    entryType: actiontype,
    optionType,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    strikeIndex: Number(strikeIndex),
    quantity: Number(quantity),
    sortId: 0
  };

  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: `${CURRENT_ONE_MINUTE_CANDLE_NAME}.close`,
      operator: comparisonType,
      rightOperand: `superTrend${period}`,
    }],
    transactionConfigs: [
      { ..._.head(transactionConfigs), optionConfigs: [{ ...optionConfigObj, ...setOptionConfig, }] }
    ]
  }];

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    pipeConfigs: [...pipeConfigs, ...pipeConfig],
    orderConfigs: modifiedOrderConfigs,
  };
};
