import { getAlteredEmptyQuantConfig } from 'modules/QuantBuilder/config/index';
import { getStopGainPercent, getStopGainPrice } from '../../config';

export const INTRADAY_STRADDLE = 'intradyStraddle';

export const intradyStraddleDefaultConfig = {
  optionType1: 'CE',
  optionType2: 'PE',
  strikeIndex1: 0,
  strikeIndex2: 0,
  stopLoss1: 0,
  stopLoss2: 0,
  stopGain1: 0,
  stopGain2: 0,
  time: '',
  actiontype1: 'sell',
  actiontype2: 'buy',
  quantity1: 1,
  quantity2: 1,
  stopLoss1Type: 'percent',
  stopLoss2Type: 'percent',
  stopGain1Type: 'percent',
  stopGain2Type: 'percent'
};

export const quantConfigConversionIntraday = (config) => {
  const {
    optionType1,
    optionType2,
    strikeIndex1,
    strikeIndex2,
    stopLoss1,
    stopLoss2,
    stopGain1,
    time,
    stopGain2,
    actiontype1,
    actiontype2,
    instruments,
    quantity1,
    quantity2,
    stopGain1Type,
    stopGain2Type,
    stopLoss1Type,
    stopLoss2Type,
    segment
  } = config;

  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs } = emptyConfig;
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const setOptionConfig1 = {
    ...optionConfigObj,
    entryType: actiontype1,
    optionType: optionType1,
    stopGainPrice: getStopGainPrice(stopGain1Type, stopGain1),
    stopLossPrice: getStopGainPrice(stopLoss1Type, stopLoss1),
    stopGain: getStopGainPercent(stopGain1Type, stopGain1),
    stopLoss: getStopGainPercent(stopLoss1Type, stopLoss1),
    strikeIndex: Number(strikeIndex1),
    quantity: Number(quantity1),
    sortId: 0
  };

  const setOptionConfig2 = {
    ...optionConfigObj,
    entryType: actiontype2,
    optionType: optionType2,
    stopGainPrice: getStopGainPrice(stopGain2Type, stopGain2),
    stopLossPrice: getStopGainPrice(stopLoss2Type, stopLoss2),
    stopGain: getStopGainPercent(stopGain2Type, stopGain2),
    stopLoss: getStopGainPercent(stopLoss2Type, stopLoss2),
    strikeIndex: Number(strikeIndex2),
    quantity: Number(quantity2),
    sortId: 1
  };

  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: 'timeOfDay',
      operator: 'equalTo',
      rightOperand: time,
    }],
    transactionConfigs: [
      {
        ..._.head(transactionConfigs),
        optionConfigs: [
          {
            ...optionConfigObj,
            ...setOptionConfig1,
          },
          {
            ...optionConfigObj,
            ...setOptionConfig2,
          }
        ]
      }
    ]
  }];

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    orderConfigs: modifiedOrderConfigs,
  };
};
