import React from 'react';
import { PRODUCT_TYPES } from 'v2/common/constants/index';
import PropTypes from 'prop-types';

const propTypes = {
  product: PropTypes.string.isRequired,
  onChangeProduct: PropTypes.func.isRequired,
};

const defaultProps = {};

const ProductType = ({ product, onChangeProduct }) => {
  const renderRadioBtn = (optionType) => {
    return (
      <div className="form-check form-check-sm" key={optionType.value}>
        <input
          className="form-check-input"
          type="radio"
          name="productType"
          id={`product-type-${optionType.value}`}
          value={optionType.value}
          tabIndex="-100"
          checked={optionType.value === product}
          onChange={(e) => onChangeProduct(e.target.value)}
        />
        <label className="form-check-label" htmlFor={`product-type-${optionType.value}`}>
          {optionType.label}
        </label>
      </div>
    );
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-2"><label className="label_style mb-0">Product Type</label></div>
        <div className="col-md-10 d-flex gap-20">{_.map(PRODUCT_TYPES, renderRadioBtn)}</div>
      </div>
    </>
  );
};

ProductType.propTypes = propTypes;
ProductType.defaultProps = defaultProps;

export default ProductType;
