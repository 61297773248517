import React from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes, signalsPropTypes } from 'common/propTypes';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import {
  OutputSchemaUtility,
  TypeheadSchemaUtility,
} from 'v2/common/quantConfig/outputSchema/utility';
import { schemaTypes } from 'v2/common/quantConfig/outputSchema/config';
import { OperandUtility } from '../utility';

let defaultParentClassName = 'border position-relative rounder p-2 d-flex ';
defaultParentClassName += 'align-items-center justify-content-between mb-2';

const propTypes = {
  signals: signalsPropTypes.isRequired,
  onEdit: PropTypes.func,
  outputSchema: outputSchemaPropTypes.isRequired,
  isViewOnly: PropTypes.bool,
  label: PropTypes.string,
  parentClassName: PropTypes.string,
};
const defaultProps = {
  isViewOnly: false,
  onEdit: () => {},
  label: '',
  parentClassName: defaultParentClassName,
};

const SignalsView = ({
  signals,
  onEdit,
  outputSchema,
  isViewOnly,
  parentClassName,
  label,
}) => {
  const namePairLabel = _.reduce(
    outputSchema,
    (acc, schema) => {
      acc[schema.name] = TypeheadSchemaUtility.getOption(schema)?.label || schema.label;
      return acc;
    },
    {}
  );

  const getModifiedRightOperand = (rightOperand) => {
    const isArray = _.isArray(rightOperand);
    if (isArray) {
      return _.chain(rightOperand)
        .map((operand) => _.startCase(operand))
        .join(', ')
        .value();
    }
    return rightOperand;
  };

  const renderSignal = (signal, idx) => {
    const {
      leftOperand, conditionOperator, rightOperand, operator
    } = signal;
    const leftOperandType = OutputSchemaUtility.findByName(
      outputSchema,
      leftOperand
    )?.type;
    const { label: operatorLabel } = _.find(
      OperandUtility.getOperators(leftOperandType),
      ({ name }) => name === operator
    ) || {};
    const isBoolean = leftOperandType === schemaTypes.boolean;
    const { name } = OutputSchemaUtility.findByName(outputSchema, rightOperand);

    let rightOperandLabel = name || rightOperand;
    if (isBoolean) {
      if (Number(rightOperand) === 0) rightOperandLabel = 'False';
      else if (Number(rightOperand) === 1) rightOperandLabel = 'True';
    }

    return (
      <div key={idx} className="d-flex align-items-center">
        {idx !== 0 && (
          <span className="font-weight-medium tx-12 mx-2 condition-oprtator">
            {_.upperCase(conditionOperator)}
          </span>
        )}
        <span className="tx-12 condition-info">
          {ReactHtmlParser(
            _.get(namePairLabel, leftOperand, _.startCase(leftOperand))
          )}
        </span>
        <u className="font-weight-medium tx-12 mx-2 underline-operator">
          {_.startCase(operatorLabel || operator)}
        </u>
        <span className="tx-12 condition-info">
          {ReactHtmlParser(
            _.get(
              namePairLabel,
              rightOperandLabel,
              getModifiedRightOperand(rightOperandLabel)
            )
          )}
        </span>
      </div>
    );
  };

  return (
    <div className={parentClassName}>
      <div className="d-flex align-items-center flex-wrap overflow-auto signal-container">
        {label && (
          <span className="font-weight-bold tx-13 mr-1 signals-type">
            {label}
          </span>
        )}
        <div className="d-flex align-items-center signals-info gap-3">
          {_.isEmpty(signals) ? (
            <span className="tx-13">
              <span className="tx-12 condition-info">Is Empty</span>
            </span>
          ) : (
            _.map(signals, renderSignal)
          )}
        </div>
      </div>
      {!isViewOnly && (
        <button
          type="button"
          className="btn btn-edit-outline btn-sm edit-btn"
          onClick={onEdit}
        >
          <i className="edit-icon mr-1" />
          <span>Edit</span>
        </button>
      )}
    </div>
  );
};

SignalsView.propTypes = propTypes;
SignalsView.defaultProps = defaultProps;

export default SignalsView;
