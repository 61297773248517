import React from 'react';
import AvailableFreeTrialCard from 'common/components/AvailableFreeTrialCard';
import { webAndMobileDefaultProps, webAndMobileRootPropTypes } from 'ui/Plans/defaultProps';
import PlanTypeSelector from '../common/PlanTypeSelector';
import PlanCard from './PlanCard';
import AddonCard from './AddonCard';
import Credits from '../common/Credits';
import PaymentCoupons from '../common/PaymentCoupons';

const propTypes = webAndMobileRootPropTypes;
const defaultProps = webAndMobileDefaultProps;

const Web = ({
  planCycleType,
  setPlanCycleType,
  activePlanId,
  activePlanName,
  addons,
  couponCode,
  setCouponCode,
  isApplied,
  appliedCouponConfig,
  creditBalance,
  setCreditBalance,
  isCreditApplied,
  freePlanDetails,
  setIsCreditApplied,
  message,
  freeTrailDays,
  currentCyclicPeriodPlans,
  plansGroupedByCyclicMonths,
  shouldShowAddons,
  isSubscriptionActive,
  planPageNotice,
  shouldShowCoupon,
  onCouponUpdate
}) => {
  const oneIndentation = ' ';

  const renderAddon = (addon, idx) => (
    <AddonCard
      couponConfig={appliedCouponConfig}
      key={idx}
      addon={addon}
      purchaseType="addon"
      credits={isCreditApplied ? creditBalance : 0}
    />
  );

  const renderAddons = () => {
    if (!shouldShowAddons) return null;
    if (_.isEmpty(addons)) return null;

    return (
      <div className="col-lg-4 col-md-6 col-12 mt-5 mb-3">
        <div className="tab-box-three">
          <div className=" d-flex align-items-center justify-content-center gap-15">
            <div>
              <h2 className="font-weight-bold mt-2 mb-2">AddOn Credits</h2>
            </div>
          </div>
          {_.map(addons, renderAddon)}
        </div>
      </div>
    );
  };

  const renderPlan = (plan, key) => {
    return (
      <div className="col-lg-4 col-md-6 col-12 mt-5 mb-3" key={key}>
        <PlanCard
          plan={plan}
          activePlanName={activePlanName}
          couponConfig={appliedCouponConfig}
          planCycleType={planCycleType}
          activePlanId={activePlanId}
          isSubscriptionActive={isSubscriptionActive}
          isCreditApplied={isCreditApplied}
          credits={isCreditApplied ? creditBalance : 0}
          couponCode={couponCode}
          onChangeCouponCode={setCouponCode}
          isApplied={isApplied}
          onCouponUpdate={onCouponUpdate}
          message={message}
        />
      </div>
    );
  };

  const renderFreePlanDetails = () => {
    if (isUserSignedIn) return null;

    const { historic_run_limit: historicRunLimit, live_run_limit: liveRunLimit } = freePlanDetails;
    const freeTrailInDays = _.get(freeTrailDays, 'default', 0);

    return (
      <div className="trial-card mt-5">
        <div className="container-fluid wrap mb-5">
          <div className="card">
            <div className="d-flex align-items-center justify-content-between ">
              <div className="trial-content">
                <h3 className="mb-1">
                  <p>Choose your plan. still not sure?</p>
                </h3>
                <h4>{freeTrailInDays !== 0 && `Try our ${freeTrailInDays}-day free trial.`}</h4>
                <span>
                  <b>{historicRunLimit}</b>
                  {oneIndentation}
                  Backtest,
                  {oneIndentation}
                  <b>{liveRunLimit}</b>
                  {oneIndentation}
                  Live, F&O and Equity
                </span>
              </div>
              <div className="ml-5">
                <button type="button">
                  <a href="/">
                    Start Premium Free Trial
                    <i className="fa-solid fa-circle-arrow-right ml-1" />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPlanTypeSelector = () => {
    return (
      <div className="d-flex align-items-center flex-column">
        <div className="switch-btn">
          <PlanTypeSelector
            planCycleType={planCycleType}
            onHandlePlanType={setPlanCycleType}
            plansGroupedByCyclicMonths={plansGroupedByCyclicMonths}
          />
        </div>
      </div>
    );
  };

  const renderPlanPageHeaders = () => {
    let headerClass = 'col-md-12 m-auto d-flex align-item-center gap-15 ';
    headerClass += 'flex-wrap flex-lg-nowrap justify-content-center';

    return (
      <>
        {renderPlanTypeSelector()}
        {shouldShowCoupon && (
          <div className={headerClass}>
            <Credits
              creditBalance={creditBalance}
              isCreditApplied={isCreditApplied}
              onUpdateCredits={
                (newCreditBalance, isCreditUsed) => {
                  setCreditBalance(newCreditBalance);
                  setIsCreditApplied(isCreditUsed);
                }
              }
            />
            <PaymentCoupons
              couponCode={couponCode}
              onChangeCouponCode={setCouponCode}
              isApplied={isApplied}
              onCouponUpdate={onCouponUpdate}
              message={message}
            />
          </div>
        )}

        {isSubscriptionActive
          && (
            <div className="alert alert-info d-flex align-items-center gap-10 my-2">
              Want to upgrade or extend plan?
              {oneIndentation}
              Please cancel your recurring subscription and continue with one-time payment.
            </div>
          )}
      </>
    );
  };

  const applicationDomainNotice = () => {
    if (!planPageNotice) { return null; }

    return (
      <div className="alert alert-info text-center mb-2">{planPageNotice}</div>
    );
  };

  return (
    <>
      <div>
        <AvailableFreeTrialCard />
        <h1 className="font-weight-bold mb-3 mb-md-5 mt-5 text-center">
          Plans for every level of ambition
        </h1>

        {applicationDomainNotice()}
        {renderPlanPageHeaders()}

      </div>
      <div>
        <div className="row plans-row">
          {_.map(_.orderBy(currentCyclicPeriodPlans, 'price', 'asc'), renderPlan)}
          {renderAddons()}
        </div>
        {renderFreePlanDetails()}
      </div>
    </>
  );
};

Web.propTypes = propTypes;
Web.defaultProps = defaultProps;

export default Web;
