import React, { useState } from 'react';
import { runPropTypes } from 'common/propTypes';
import Modal from 'common/components/ModalComponent';
import { ORDER_TYPES, RUN_TYPES } from 'v2/common/constants/index';
import { getOutputSchema } from 'v2/common/quantConfig/outputSchema/index';
import { getSanitizedQuantConfig } from 'v2/modules/withRunForm/sanitizer/onLoad/quantConfig/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import ViewConfigInstrumentalDetails from './ViewConfigInstrumentalDetails';
import ViewConfigIndicators from './ViewConfigIndicators';
import ViewConfigCases from './ViewConfigCases';
import ConfigRunDetails from './ConfigRunDetails';
import ViewConfigExpiry from './ViewConfigExpiry';
import ViewConfigStopValues from './ViewConfigStopValues';
import ViewConfigOrdersProduct from './ViewConfigOrdersProduct';
import ViewAdjustmentAdvancedOptions from './ViewAdjustmenOptions';

const propTypes = { run: runPropTypes.isRequired, };
const defaultProps = { };

const ViewRunConfigs = ({ run }) => {
  const [shouldShowModal, setShowModal] = useState(false);
  const title = 'View Strategy Details';
  const quantConfig = _.get(run, 'quant_config', {});

  if (_.isEmpty(quantConfig)) return null; // for hided quant_config

  const outputSchema = getOutputSchema(quantConfig);

  const {
    run_type: runType, start_date: startDate, end_date: endDate, folder_name:
    folderName, order_type: orderType, display_name: runName, id, granularity
  } = run;
  const sanitizedQuantConfig = getSanitizedQuantConfig(quantConfig);
  const {
    instrumentGroups,
    maxInstrumentsInGroup = _.head(instrumentGroups).length,
    pipeConfigs, cases,
    bounds: {
      maxTransactionsPerDay,
      timeBasedSignal,
      expiryHandling,
      stopLoss,
      stopGain,
      dayStopLoss,
      dayStopGain
    },
    adjustmentOptions,
    tradingType,
    entryOrderType, exitOrderType, product, entryOrderModification, exitOrderModification,
    isPlaceOptionsEntryBuyOrdersFirst, isPlaceStoplossOrderToBroker, isSquareOffTransactionIfEntryFailed
  } = sanitizedQuantConfig;

  const slOrderPlaceBasedOnPrice = _.get(quantConfig, 'slOrderPlaceBasedOnPrice', null);
  const limitOrderBufferPercentage = _.get(quantConfig, 'limitOrderBufferPercentage', null);

  const groupType = maxInstrumentsInGroup === 1 ? 'Single' : 'Pair';
  const isLive = runType === RUN_TYPES.live;
  const { paper: { value: paper } } = ORDER_TYPES;
  const isActualOrder = orderType !== paper && isLive;

  return (
    <>
      <button
        className="btn-sm btn btn-outline-primary text-nowrap copy-btn track"
        data-toggle="tooltip"
        data-placement="top"
        onClick={() => setShowModal(true)}
        type="button"
        data-track-category="View Run Configs"
        data-track-action="RunConfigs Display"
        data-track-label="Show Page"
      >
        {title}
      </button>
      {shouldShowModal
        && (
          <Modal onClose={() => setShowModal(false)} title={title} size="xl">
            <div className="view-run-details">
              <ConfigRunDetails
                runType={runType}
                startDate={startDate}
                endDate={endDate}
                runName={runName || id}
                folderName={folderName}
                orderType={orderType}
                granularity={granularity}
              />

              <div className="instruments-builder p-0">
                <ViewConfigInstrumentalDetails groupType={groupType} instrumentGroups={instrumentGroups} />
              </div>
              {!(_.isEmpty(pipeConfigs)) && (
                <ViewConfigIndicators
                  pipeConfigs={pipeConfigs}
                  hasMultipleInstruments={groupType === 'Pair'}
                  outputSchema={outputSchema}
                />
              )}
              <ViewConfigCases cases={cases} outputSchema={outputSchema} />
              <ViewAdjustmentAdvancedOptions adjustmentOptions={adjustmentOptions} />
              <ViewConfigExpiry
                tradingType={tradingType}
                timeBasedSignal={timeBasedSignal}
                expiryHandling={expiryHandling}
                maxTransactionsPerDay={maxTransactionsPerDay}
              />
              <ViewConfigStopValues
                stopLoss={stopLoss}
                stopGain={stopGain}
                dayStopLoss={dayStopLoss}
                dayStopGain={dayStopGain}
              />
              {isActualOrder && (
                <ViewConfigOrdersProduct
                  entryOrderType={entryOrderType}
                  exitOrderType={exitOrderType}
                  product={product}
                  entryOrderModification={entryOrderModification || {}}
                  exitOrderModification={exitOrderModification || {}}
                  slOrderPlaceBasedOnPrice={slOrderPlaceBasedOnPrice}
                  limitOrderBufferPercentage={limitOrderBufferPercentage}
                  isPlaceOptionsEntryBuyOrdersFirst={
                    _.isUndefined(isPlaceOptionsEntryBuyOrdersFirst) ? null
                      : isPlaceOptionsEntryBuyOrdersFirst
                  }
                  isPlaceStoplossOrderToBroker={
                    _.isUndefined(isPlaceStoplossOrderToBroker) ? null
                      : isPlaceStoplossOrderToBroker
                  }
                  isSquareOffTransactionIfEntryFailed={
                    _.isUndefined(isSquareOffTransactionIfEntryFailed) ? null
                      : isSquareOffTransactionIfEntryFailed
                  }
                />
              )}

            </div>
          </Modal>
        )}
    </>
  );
};

ViewRunConfigs.propTypes = propTypes;
ViewRunConfigs.defaultProps = defaultProps;

export default withErrorBoundary(ViewRunConfigs);
