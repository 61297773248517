const LOGIN_WATCH_INTERVAL = 1000;

const getWindowProperty = (window, propertyName) => {
  try {
    return window[propertyName];
  } catch (err) {
    console.log(`caught error${err}`);
  }

  return false;
};

export const initiateUserAuth = () => {
  const loginWindow = window.open(
    '/popup_login?origin=pop_up_window',
    'newWindow',
    'height=570,width=1020,scrollbars=yes,status=yes,modal=yes,alwaysRaised=yes'
  );

  return new Promise((resolve, reject) => {
    const loginWatch = setInterval(async () => {
      if (getWindowProperty(loginWindow, 'closed') || getWindowProperty(loginWindow, 'complete')) {
        clearInterval(loginWatch);
        await fetch('/clear_auth_window_session');

        if (getWindowProperty(loginWindow, 'complete') && getWindowProperty(loginWindow, 'loginSuccess')) {
          loginWindow.close();
          resolve();
        }

        reject();
      }
    }, LOGIN_WATCH_INTERVAL);
  });
};
