import React from 'react';
import PropTypes from 'prop-types';
import Selector from './Selector';

const propTypes = {
  isPlaceOptionsEntryBuyOrdersFirst: PropTypes.bool.isRequired,
  onChangeIsPlaceOptionsEntryBuyOrdersFirst: PropTypes.func.isRequired,
};
const defaultProps = {};

const MarginBenefit = ({ isPlaceOptionsEntryBuyOrdersFirst, onChangeIsPlaceOptionsEntryBuyOrdersFirst }) => {
  const onChange = (booleanValue) => {
    onChangeIsPlaceOptionsEntryBuyOrdersFirst(booleanValue);
  };

  return (
    <div className="row mb-4">
      <div className="col-md-2"><label className="label_style mb-0">Margin Benefit</label></div>
      <div className="col-md-10 margin-benefit">
        <Selector
          optionType={isPlaceOptionsEntryBuyOrdersFirst}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

MarginBenefit.propTypes = propTypes;
MarginBenefit.defaultProps = defaultProps;

export default MarginBenefit;
