import React from 'react';
import PropTypes from 'prop-types';

const propTypes = { multiplier: PropTypes.number };
const defaultProps = { multiplier: 1 };

const MultiplierTag = ({ multiplier }) => {
  if (!multiplier || multiplier === 1) return null;
  const oneIndentation = ' ';

  return (
    <div className="multiplier-tag">
      <i className="tx-17 material-icons-outlined">
        privacy_tip
      </i>
      <span className="font-weight-bold tx-15">{multiplier}</span>
      {oneIndentation}
      Multiplier per Lot
    </div>
  );
};

MultiplierTag.propTypes = propTypes;
MultiplierTag.defaultProps = defaultProps;

export default MultiplierTag;
