import React from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes } from 'common/propTypes';
import PipeConfigView from 'v2/modules/withRunForm/components/PipeConfigs/PipeConfigView/index';

const propTypes = {
  pipeConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {};

const ViewConfigIndicators = ({ pipeConfigs, outputSchema }) => {
  const renderIndicators = (pipeConfig, key) => {
    return (
      <div className="row" key={key}>
        <div className="col-md-12">
          <div className="config-indicators">
            <div className="d-flex align-items-center flex-wrap gap-5">
              <PipeConfigView
                pipeConfig={pipeConfig}
                outputSchema={outputSchema}
                isViewOnly
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0">Indicators</h5>
      </div>
      <div className="card-body d-flex flex-column gap-10">
        {_.map(pipeConfigs, renderIndicators)}
      </div>
    </div>
  );
};

ViewConfigIndicators.propTypes = propTypes;
ViewConfigIndicators.defaultProps = defaultProps;

export default ViewConfigIndicators;
