import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import { expiryHandlingPropsTypes } from 'common/propTypes';
import TimeSelector from 'common/components/TimeSelector';
import { getTimeValidators } from 'common/utils/validators';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  expiryHandling: expiryHandlingPropsTypes.isRequired,
  onChangeExpiryHandling: PropTypes.func.isRequired,
};
const defaultProps = {};

const Positional = (props) => {
  const {
    expiryHandling: { exitTime, exitDaysBefore },
    expiryHandling,
    onChangeExpiryHandling,
  } = props;
  const { errors, control } = useFormContext();
  const [timePickerName] = useState(_.uniqueId('positionalTimePicker-'));

  const onExpiryHandlingChange = (newConfig) => {
    onChangeExpiryHandling({ ...expiryHandling, ...newConfig });
  };

  const renderDayBeforeExpiryOptions = _.map(_.range(0, 60), (day) => (
    <option key={day} value={day}>{day}</option>
  ));

  return (
    <div className="positional-exit-handler">
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="label_style mb-0">Expiry Handling</label>
        </div>
        <div className="col-md-10 d-flex flex-wrap">
          <div className="input-group d-flex gap-5">
            <Form.Control
              className="form-control"
              as="select"
              value={exitDaysBefore}
              onChange={(event) => onExpiryHandlingChange({
                exitDaysBefore: parseInt(event.target.value, 10)
              })}
            >
              <option key="default-option" value="" />
              {renderDayBeforeExpiryOptions}
            </Form.Control>
            <div className="input-group-append">
              <span className="input-group-text">Days Before Expiry</span>
            </div>
            <ErrorMesssage message={errors[timePickerName]?.message || ''}>
              <Controller
                render={({ onChange }) => (
                  <TimeSelector
                    style={timePickerName}
                    onTimeChange={(newExitTime) => {
                      onExpiryHandlingChange({ exitTime: newExitTime });
                      onChange(newExitTime);
                    }}
                    selectedTime={exitTime}
                  />
                )}
                control={control}
                defaultValue={exitTime}
                name={timePickerName}
                rules={getTimeValidators()}
              />
            </ErrorMesssage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Positional;

Positional.propTypes = propTypes;
Positional.defaultProps = defaultProps;
