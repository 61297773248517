import React from 'react';

const propTypes = {};
const defaultProps = {};

const NuvamaLogin = () => {
  const oneIndentation = ' ';

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Nuvama API.
            <br />
            <br />
            <a href="/auth/nuvama" className="btn btn-outline-primary broker">
              Login via
              {oneIndentation}
              <b>Nuvama</b>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

NuvamaLogin.propTypes = propTypes;
NuvamaLogin.defaultProps = defaultProps;

export default NuvamaLogin;
