import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { Controller } from 'react-hook-form';
import { outputSchemaPropTypes, rightOperandPropTypes } from 'common/propTypes';
import { OutputSchemaUtility, TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  onChangeRightOperand: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isAllowNew: PropTypes.bool.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
};

const defaultProps = {
  selected: [],
  options: [],
};

const Default = ({
  onChangeRightOperand,
  selected,
  options,
  control,
  errors,
  isAllowNew,
  outputSchema,
  rightOperand
}) => {
  const uniqueId = useMemo(() => _.uniqueId('right-operand-'), []);
  const errorMessage = useMemo(() => _.get(errors, `${uniqueId}.message`, ''), [errors, uniqueId]);
  const selectedRightOperandSchema = useMemo(() => {
    return OutputSchemaUtility.findByName(outputSchema, rightOperand);
  }, [outputSchema, rightOperand]);

  const validator = {
    validate: (value) => {
      if (_.isArray(value) && _.isEmpty(value[0]?.searchLabel)) return 'Please select a Right operand';

      return null;
    }
  };

  const onChangeOrOnBlur = (result, onChange) => {
    if (_.isEmpty(result)) return;
    const { id, customOption, searchLabel } = result[0];

    onChange(result);
    if (customOption) {
      const newLabel = Number(searchLabel);
      if (Number.isNaN(newLabel)) {
        onChangeRightOperand('');
        return;
      }
      onChangeRightOperand(searchLabel);
    } else {
      onChangeRightOperand(id);
    }
  };

  const onBlur = (event, onChange) => {
    const result = event.target.value;
    const newLabel = Number(result);
    if (!Number.isNaN(newLabel) && isAllowNew) {
      onChangeOrOnBlur(
        [{ customOption: true, searchLabel: result, id: _.uniqueId('new-id-') }],
        onChange
      );
    }
  };

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange, ref, name }) => (
          <Typeahead
            className="custom-form-control"
            disabled={false}
            labelKey="searchLabel"
            ref={ref}
            isInvalid={false}
            id={name}
            allowNew={isAllowNew}
            options={_.uniqBy(_.flattenDeep([options || [], selected || []]), 'searchLabel')}
            renderMenuItemChildren={(option) => ReactHtmlParser(option.label)}
            filterBy={(
              option, filterProps
            ) => TypeheadSchemaUtility.getOptionFilteredBySearchText(option, filterProps)}
            selected={selected}
            clearButton={!_.isEmpty(selected)}
            onBlur={(event) => onBlur(event, onChange)}
            onChange={(result) => onChangeOrOnBlur(result, onChange)}
          />
        )}
        control={control}
        defaultValue={selected}
        name={uniqueId}
        rules={validator}
      />
      {selectedRightOperandSchema?.infoLink && (
      <span
        className="material-icons-outlined info-icon ml-1"
        onClick={() => window.open(selectedRightOperandSchema?.infoLink, '_blank')}
      >
        info
      </span>
        )}
    </ErrorMesssage>
  );
};

Default.propTypes = propTypes;
Default.defaultProps = defaultProps;

export default Default;
