import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Money from 'common/components/MoneyDisplay';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getPurchases } from 'common/api/payment';
import { planCycleLabel } from 'modules/UpgradePlan/helper';

import InvoiceContent from './InvoiceContent';
import { generatePaymentInvoice } from './InvoiceContent/paymentInvoice';

const propTypes = {
  gstPercentage: PropTypes.number.isRequired,
  quantManGstNumber: PropTypes.string
};

const defaultProps = {
  quantManGstNumber: null
};

const oneIndentation = ' ';
const PurchaseSettings = ({ gstPercentage, quantManGstNumber }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [purchases, setPurchases] = useState([]);

  if (isLoading === null) {
    getPurchases()
      .then((retrievedPurchases) => setPurchases(retrievedPurchases))
      .finally(() => setIsLoading(false));
  }

  if (_.isEmpty(purchases)) {
    return (
      <div className="text-center">
        <img src="/no-receipts.svg" alt="No Receipts" className="no-receipts-img" />
      </div>
    );
  }

  const renderPurchaseItem = (purchaseItem) => {
    let content = null;
    if (purchaseItem.purchasable_type === 'Plan') {
      const planCycleDurationInMonths = _.get(purchaseItem, 'purchasable.cycle_duration_in_months');
      content = (
        <>
          {_.get(purchaseItem, 'purchasable.plan_name')}
          {oneIndentation}
          <small className="sub">{planCycleLabel(planCycleDurationInMonths)}</small>
        </>
      );
    } else if (purchaseItem.purchasable_type === 'Addon') {
      content = (
        <>
          {_.get(purchaseItem, 'purchasable.addon_name')}
          (Addon)
        </>
      );
    } else {
      content = `(${purchaseItem.purchasable_type})`;
    }
    return (
      <div className="purchase-item" key={purchaseItem.id}>
        {content}
      </div>
    );
  };

  const renderTableBody = (purchase) => {
    return (
      <tr key={purchase.id}>
        <td>{purchase.id}</td>
        <td>{_.map(purchase.purchase_items, renderPurchaseItem)}</td>
        <td><Money shouldAvoidDecimals>{purchase.price_total}</Money></td>
        <td>{purchase.status}</td>
        <td>{moment(purchase.created_at).format('YYYY-MM-DD')}</td>
        <td>
          <span
            className="material-icons-outlined receipt"
            onClick={() => generatePaymentInvoice(purchase)}
          >
            receipt
          </span>
        </td>
        <td className="hidden-for-future">
          <div id={`purchase-${purchase.id}`}>
            <InvoiceContent
              purchase={purchase}
              quantManGstNumber={quantManGstNumber}
              gstPercentage={gstPercentage}
            />
          </div>
        </td>
      </tr>
    );
  };

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th>Purchase Id</th>
        <th>Items</th>
        <th>Amount</th>
        <th>Status</th>
        <th>Paid at</th>
        <th>Invoice</th>
      </tr>
    </thead>
  );

  return (
    <div className="purchase-settings card mb-4">
      <div className="table-responsive">
        <LoadingSpinner isLoadin={isLoading}>
          <table className="table mb-0">
            {renderTableHeader()}
            <tbody className="payment-details-body">
              {_.map(purchases, renderTableBody)}
            </tbody>
          </table>
        </LoadingSpinner>
      </div>
    </div>
  );
};

PurchaseSettings.propTypes = propTypes;
PurchaseSettings.defaultProps = defaultProps;

export default PurchaseSettings;
