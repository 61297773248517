import { schemaTypes } from 'v2/common/quantConfig/outputSchema/config';
import DayOfWeek from './SignalsForm/Signal/RightOperand/DayOfWeek';
import Default from './SignalsForm/Signal/RightOperand/Default';
import Duration from './SignalsForm/Signal/RightOperand/Duration';
import Time from './SignalsForm/Signal/RightOperand/Time';

const config = {
  [schemaTypes.dayOfWeek]: {
    operator: [
      { name: 'contains', label: 'Contains' }
    ],
    rightOperandOptions: [
      { name: 'monday', label: 'Monday' },
      { name: 'tuesday', label: 'Tuesday' },
      { name: 'wednesday', label: 'Wednesday' },
      { name: 'thursday', label: 'Thursday' },
      { name: 'friday', label: 'Friday' },
      { name: 'saturday', label: 'Saturday' },
    ],
    rightOperandComponent: DayOfWeek,
    options: [],
    isAllowNew: false
  },
  [schemaTypes.target]: {
    operator: [
      { name: 'equalTo', label: 'Equal to' },
      { name: 'isAbove', label: 'Is above' },
      { name: 'isBelow', label: 'Is below' }
    ],
    rightOperandOptions: _.map(_.range(0, 31), (day) => ({
      name: day, label: day
    })),
    rightOperandComponent: Default,
    isAllowNew: false
  },
  [schemaTypes.minute]: {
    operator: [
      { name: 'equalTo', label: 'Equal to' },
      { name: 'isAbove', label: 'Is above' },
      { name: 'isBelow', label: 'Is below' }
    ],
    rightOperandOptions: _.map(_.range(0, 61), (minute) => ({
      name: minute, label: minute
    })),
    rightOperandComponent: Default,
    isAllowNew: false
  },
  [schemaTypes.boolean]: {
    operator: [
      { name: 'equalTo', label: 'Is' },
    ],
    rightOperandOptions: [
      { name: 1, label: 'true' },
      { name: 0, label: 'false' }
    ],
    rightOperandComponent: Default,
    isAllowNew: false
  },
  [schemaTypes.number]: {
    operator: [
      { name: 'equalTo', label: 'Equal to' },
      { name: 'isAbove', label: 'Is above' },
      { name: 'isBelow', label: 'Is below' },
      { name: 'crossesAbove', label: 'Crosses above' },
      { name: 'crossesBelow', label: 'Crosses below' },
      { name: 'equalOrAbove', label: 'Equal or above' },
      { name: 'equalOrBelow', label: 'Equal or below' }
    ],
    rightOperandComponent: Default,
    isAllowNew: true
  },
  [schemaTypes.duration]: {
    operator: [
      { name: 'isAbove', label: 'Is above' },
      { name: 'isBelow', label: 'Is below' }
    ],
    rightOperandComponent: Duration,
    isAllowNew: false
  },
  [schemaTypes.time]: {
    operator: [
      { name: 'equalTo', label: 'Equal to' },
      { name: 'isAbove', label: 'Is above' },
      { name: 'isBelow', label: 'Is below' }
    ],
    rightOperandComponent: Time,
    isAllowNew: false
  },
};
export const OperandUtility = (() => {
  return {
    getOperators: (type = '') => {
      return config[type]?.operator || [];
    },

    getRightOperandConfigs: (type) => {
      return {
        component: config[type]?.rightOperandComponent || Default,
        options: config[type]?.rightOperandOptions || [],
        isAllowNew: config[type]?.isAllowNew || false
      };
    },
  };
})();
