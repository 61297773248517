import React from 'react';
import { isStrategyDomainUser } from 'ui/applicationDomainHelper';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import HoveMessagedBasedOnType from './HoveMessagedBasedOnType';

const propTypes = {
  isPremium: PropTypes.bool,
  liveRunLimit: PropTypes.number,
  historicRunLimit: PropTypes.number,
  parentClassName: PropTypes.string
};

const defaultProps = {
  isPremium: false,
  liveRunLimit: 0,
  historicRunLimit: 0,
  parentClassName: ''
};

const PlanContent = ({
  isPremium, liveRunLimit, historicRunLimit, parentClassName
}) => {
  const oneIndentation = ' ';

  const contentList = [
    {
      id: 'calendarSpread',
      content: (
        <>Calendar Spread</>
      ),
      isChecked: isPremium
    },
    {
      id: 'strikeMultiplierAndAddCases',
      content: (
        <>
          Strike Multiplier and Add cases
        </>
      ),
      isChecked: isPremium
    },
    {
      id: 'customAdjustment',
      content: (
        <>
          Adjustment Features
        </>
      ),
      isChecked: isPremium
    },
    {
      id: 'marketPlacePremiumStrategyDeploy',
      content: (
        <>
          Marketplace
          {oneIndentation}
          <b>Exclusive Premium</b>
          {oneIndentation}
          strategies
        </>
      ),
      isChecked: isPremium
    },
    {
      id: 'backTestCredits',
      content: (
        <div className="d-flex align-items-center">
          <b className="mr-1">{historicRunLimit}</b>
          {oneIndentation}
          Backtest Credits
          <HoveMessagedBasedOnType type="backTestCredits" />
        </div>
      ),
      isChecked: true
    },
    {
      id: 'liveDeploymentCredits',
      content: (
        <div className="d-flex align-items-center">
          <b className="mr-1">{liveRunLimit}</b>
          Live Deployment Credits
          <HoveMessagedBasedOnType type="liveDeploymentCredits" />
        </div>
      ),
      isChecked: true
    },
  ];

  const renderContent = ({ content, id, isChecked }) => {
    const className = classNames('fa-solid', {
      'fa-square-check': isChecked,
      'fa-square': !isChecked
    });

    const renderSpan = isChecked ? <span>{content}</span> : <span><del>{content}</del></span>;

    return (
      <React.Fragment key={id}>
        <li>
          <div><i className={className} /></div>
          <div>{renderSpan}</div>
        </li>
      </React.Fragment>
    );
  };

  const ulClassName = parentClassName || classNames({
    'premium-plan-list': isPremium,
    'plan-list': !isPremium
  });

  return (
    <ul className={ulClassName}>
      {_.map(isStrategyDomainUser() ? _.takeRight(contentList, 2) : contentList, renderContent)}
    </ul>
  );
};

PlanContent.propTypes = propTypes;
PlanContent.defaultProps = defaultProps;

export default PlanContent;
