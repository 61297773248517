// vendor imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// project imports
import { runPropTypes, userPropTypes } from 'common/propTypes';
import { SEGMENT_CONFIG } from 'common/constants/index';
import { getQuantSupportSegment } from 'ui/run/RunForm/helper';
import AccessTokenInValidMessage from 'ui/Settings/Components/AccessTokenInValidMessage';
import classNames from 'classnames';
import AvailableFreeTrialCard from 'common/components/AvailableFreeTrialCard';
import RecentRuns from './components/RecentRuns';
import StarredRuns from './components/StarredRuns/index';
import ReadyMadeTemplates from './components/ReadyMadeTemplates/index';
import StrategyBuilder from './components/StrategyBuilder/index';
import MeetBanner from './components/MeetBanner/index';
import AccountDetails from './components/AccountDetails/index';
import LiveTransactions from './components/LiveTransactions/index';
import DashboardDeployedStrategies from './components/MarkerPlaceStrategies/DashboardDeployedStrategies';
import DashboardMarketPlaceStrategies
  from './components/MarkerPlaceStrategies/DashboardMarketPlaceStrategies';
import ShowCaseRunCards from './components/ShowCaseRunCards/index';
import GoToMarketPlaceBanner from './components/MarkerPlaceStrategies/GoToMarketPlaceBanner';
import GoToCreateStrategyBanner from './components/MarkerPlaceStrategies/GoToCreateStrategyBanner';

const propTypes = {
  showcasedRuns: PropTypes.arrayOf(runPropTypes).isRequired,
  historicRuns: PropTypes.arrayOf(runPropTypes),
  liveRuns: PropTypes.arrayOf(runPropTypes),
  starredRuns: PropTypes.arrayOf(runPropTypes),
  googleMeetConfig: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserDetails: userPropTypes,
  plan: PropTypes.shape({}),
  currentUserDefaultFolderId: PropTypes.number
};
const defaultProps = {
  historicRuns: [],
  liveRuns: [],
  starredRuns: [],
  googleMeetConfig: [],
  currentUserDetails: null,
  plan: null,
  currentUserDefaultFolderId: null
};

const Home = ({
  showcasedRuns, historicRuns, liveRuns, starredRuns,
  googleMeetConfig, currentUserDetails, plan, currentUserDefaultFolderId
}) => {
  const { isUserSignedIn, SA_FEATURE_FLAGS } = window;
  const isShowMarketplaceInDashboard = isUserSignedIn && SA_FEATURE_FLAGS.isMarketPlaceAllowed;
  const segments = _.get(plan, 'segments', []);
  const [activeTab, setActiveTab] = useState(
    isShowMarketplaceInDashboard ? 'marketplace' : 'optionsBuilder'
  );

  const homeTabs = [
    {
      isShow: isShowMarketplaceInDashboard,
      value: 'marketplace',
      name: 'Marketplace',
      icon: () => { return <i className="material-icons-outlined">storefront</i>; },
      returnRenderer: () => {
        return (
          <div className="dashboard-marketplace">
            <DashboardMarketPlaceStrategies
              currentUserDetails={currentUserDetails}
              currentUserDefaultFolderId={currentUserDefaultFolderId}
            />
          </div>
        );
      }
    },
    {
      isShow: true,
      value: 'deployedStrategies',
      name: 'Deployed Strategies',
      icon: () => { return <i className="material-icons-outlined">rocket_launch</i>; },
      returnRenderer: () => {
        return (<DashboardDeployedStrategies />);
      }
    },
    {
      isShow: true,
      value: 'optionsBuilder',
      name: 'Options Builder',
      icon: () => { return <i className="material-icons-outlined">tune</i>; },
      returnRenderer: () => {
        return (<StrategyBuilder segments={segments} plan={plan} />);
      }
    },
    {
      isShow: false,
      value: 'indicatorTemplates',
      name: 'Indicator Templates',
      icon: () => { return <i className="material-icons-outlined">layers</i>; },
      returnRenderer: () => {
        return (
          <ReadyMadeTemplates
            segments={segments}
            segment={getQuantSupportSegment(plan, SEGMENT_CONFIG.future)}
          />
        );
      }
    }
  ];

  const renderHomeTabs = (config, idx) => {
    if (!config.isShow) return null;

    const listClassName = classNames('list-item', { active: config.value === activeTab });
    return (
      <li
        role="presentation"
        className={listClassName}
        key={idx}
        onClick={() => { setActiveTab(config.value); }}
      >
        <span>
          {config.icon()}
          {config.name}
        </span>
      </li>
    );
  };

  const returnComponent = _.find(homeTabs, ({ value }) => value === activeTab);

  return (
    <div className="home">
      <MeetBanner googleMeetConfig={googleMeetConfig} />
      <div className="content-area">
        <div className="mobile-top-bg" />
        <div className="container px-md-3 px-0 mt-4">
          <AvailableFreeTrialCard />
          {isShowMarketplaceInDashboard ? <GoToMarketPlaceBanner /> : <GoToCreateStrategyBanner />}
          <AccountDetails currentUserDetails={currentUserDetails} />
          <LiveTransactions />
        </div>

        <div className="custom-tabs">
          <div className="tabs-list-warpper">
            <div className="container tabs-navbar">
              <ul className="tabs-list">{_.map(homeTabs, renderHomeTabs)}</ul>
            </div>
          </div>
          <div className="container tabs-content-wrapper">
            {_.get(returnComponent, 'returnRenderer', () => { })()}
          </div>
        </div>

        <div className="container my-5 px-md-3 px-0">
          <ShowCaseRunCards
            showcasedRuns={showcasedRuns}
            currentUserDefaultFolderId={currentUserDefaultFolderId}
          />
          <div className="row">
            <RecentRuns runs={historicRuns} containerClsName="recent-historic-runs-container" />
            <RecentRuns runs={liveRuns} containerClsName="recent-live-runs-container" />
          </div>
          <StarredRuns starredRuns={starredRuns} />
          <AccessTokenInValidMessage currentUser={currentUserDetails} />
        </div>
      </div>
    </div>
  );
};

export default Home;

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;
