import React from 'react';
import PropTypes from 'prop-types';
import { intentPropTypes } from 'common/propTypes';
import { useModifyExistingIntent } from 'v2/modules/withRunForm/hooks/useIntent/useModifyExistingIntent';
import TargetSelector from './TargetSelector';
import IntentTypeSelector from '../IntentTypeSelector';

const propTypes = {
  intent: intentPropTypes.isRequired,
  renderDeleteBtn: PropTypes.element,
};

const defaultProps = {
  renderDeleteBtn: null,
};

const ModifyExistingLegsIntent = ({ intent, renderDeleteBtn }) => {
  const intentConfigs = useModifyExistingIntent(intent);

  return (
    <div className="transaction_details body">
      <IntentTypeSelector
        value={intent.type}
        isAdjustmentIntent
        onChange={intentConfigs.onChangeIntentType}
      />
      <div className="transaction_details-items transction-instruments">
        <TargetSelector
          target={intentConfigs.target?.label || ''}
          onChangeTarget={intentConfigs.onChangeTarget}
        />
      </div>
      {renderDeleteBtn && renderDeleteBtn}
    </div>
  );
};

ModifyExistingLegsIntent.propTypes = propTypes;
ModifyExistingLegsIntent.defaultProps = defaultProps;

export default ModifyExistingLegsIntent;
