import React from 'react';
import PropTypes from 'prop-types';
import { STOPS_VALUE_TYPES } from 'common/constants/index';

const propTypes = {
  config: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};

const renderType = (config) => {
  const origin = _.get(config, 'origin.type', 'self');
  const isSelf = origin === 'self';
  if (config.type === STOPS_VALUE_TYPES.Percent) return isSelf ? '%' : '% Spot';
  if (config.type === STOPS_VALUE_TYPES.Amount) return isSelf ? '₹' : '₹ Spot';
  return isSelf ? 'Pts' : 'Pts Spot';
};

const renderTrailingStopLossConfig = (trail, config) => {
  if ((_.isUndefined(trail?.trailingValue) || !trail?.trailingValue)
  || (_.isUndefined(trail?.adjustValue || !trail?.adjustValue)
  )) return null;

  return ` and TSL X(${trail.trailingValue}${renderType(config)}), `
    + `Y(${trail.adjustValue}${renderType(config)})`;
};

const renderBookProfitConfig = (trail, config) => {
  if ((_.isUndefined(trail?.bookProfitOn) || !trail?.bookProfit)
  || (_.isUndefined(trail?.bookProfit || !trail?.bookProfitOn)
  )) return null;

  return ` and TSL P1(${trail.bookProfitOn}${renderType(config)}), `
    + `P2(${trail.bookProfit}${renderType(config)})`;
};

export const renderTrail = (trail, config) => {
  return (
    <>
      {renderBookProfitConfig(trail, config)}
      {renderTrailingStopLossConfig(trail, config)}
    </>
  );
};

const StopConfigView = ({ config, type }) => {
  const renderStopConfig = () => {
    if (_.isUndefined(config?.value) || !config?.value) return null;
    // only check undefined but why i check 0 ? it means
    // getSanitizedQuantConfig will return 0 if value is undefined ? it will give
    // formatted quantConfig replace the option_0_1 with leg_1 and option_0_2 with leg_2
    // once leg based changes are done, we can remove this check

    return (
      <span>
        <span className="badge badge-light badge-pill mr-1 border">
          with
          {' '}
          {type}
          {' '}
          {config.value}
          {renderType(config)}
        </span>
        {renderTrail(config?.trail, config)}
      </span>
    );
  };

  return renderStopConfig();
};

StopConfigView.propTypes = propTypes;

export default StopConfigView;
