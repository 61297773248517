import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setAdjustmentOptions } from 'v2/modules/withRunForm/redux/actions';
import withErrorBoundary from 'v2/common/components/withErrorBoundary/index';
import RenderExecutionType from './RenderExecutionType';
import RenderDelayInput from './RenderDelayInput';

const EXECUTION_TYPES = {
  nextMinute: { value: 'nextMinute', label: 'Next Minute Start' },
  immediate: { value: 'immediate', label: 'Immediate' },
  delayBy: { value: 'delayBy', label: 'Delay By' }
  // While update state, will replace delayBy with delayBySeconds
};

const AdjustmentOptions = () => {
  const dispatch = useDispatch();
  const adjustmentOptions = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.adjustmentOptions')
  );

  const onUpdateAdjustmentOptions = (newAdjustmentOptions) => {
    dispatch(setAdjustmentOptions(newAdjustmentOptions));
  };

  const executionType = _.includes(['immediate', 'nextMinute'], adjustmentOptions.executionType)
    ? adjustmentOptions.executionType : EXECUTION_TYPES.delayBy.value;

  const delay = executionType === EXECUTION_TYPES.delayBy.value
    ? _.parseInt(adjustmentOptions.executionType) : 5;

  const onExecutionTypeChange = (newExecutionType) => {
    onUpdateAdjustmentOptions({
      ...adjustmentOptions,
      executionType: newExecutionType === 'delayBy' ? `${delay}seconds` : newExecutionType
    });
  };

  const onChangeDelay = (newDelay) => {
    onUpdateAdjustmentOptions({ ...adjustmentOptions, executionType: `${parseInt(newDelay, 10)}seconds` });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <label className="label_style mb-2 mb-sm-0">
            Adjustment / ReEntry / ReExecute / OpenNewlegs
          </label>
        </div>
        <div className="col-md-10">
          <RenderExecutionType
            onChange={onExecutionTypeChange}
            executionType={executionType}
          />
        </div>
        <div className="col-md-10 offset-2 mt-3">
          {executionType === EXECUTION_TYPES.delayBy.value && (
            <RenderDelayInput delay={delay} onUpdate={onChangeDelay} />
          )}
        </div>
      </div>
    </>
  );
};

export default withErrorBoundary(AdjustmentOptions);
