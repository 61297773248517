import { INTENT_TYPES } from 'v2/common/constants/index';
import { useIntent } from './index';

export const useBasketIntent = (intent) => {
  const {
    createOnChangeHandler,
    intentId,
    segment,
    onChangeSegment,
    onChangeIntentType,
    segmentOptionConfigs,
    isShowInstrumentIndex
  } = useIntent(intent);

  return {
    name: intent?.name || '',
    onChangeBasketName: createOnChangeHandler('name'),
    multiplier: intent?.multiplier,
    onChangeMultiplier: createOnChangeHandler('multiplier'),
    onChangeIntentType,
    intentId,
    segment: segment || INTENT_TYPES.OpenBasket,
    onChangeSegment,
    segmentOptionConfigs,
    isShowInstrumentIndex,
  };
};
