import { getAlteredEmptyQuantConfig } from 'modules/QuantBuilder/config/index';
import {
  current1minuteCandle, CURRENT_ONE_MINUTE_CANDLE_NAME, getStopGainPercent, getStopGainPrice
} from '../../config';

export const SUPER_TREND_AND_RSI = 'superTrendAndRsi';

export const superTrendAndRsiDefaultConfig = {
  comparisonType: 'crossesAbove',
  period: 10,
  periodRsi: 10,
  comparisonTypeRsi: 'crossesAbove',
  multiplier: 2,
  interval: '1 minutes',
  actiontype: 'buy',
  optionType: 'CE',
  strikeIndex: 0,
  stopLoss: 0,
  stopGain: 0,
  quantity: 1,
  stopLossType: 'percent',
  stopGainType: 'percent',
};

export const quantConfigConversionStAndRsi = (config) => {
  const {
    comparisonType,
    period,
    periodRsi,
    comparisonTypeRsi,
    multiplier,
    interval,
    actiontype,
    optionType,
    strikeIndex,
    stopLoss,
    stopGain,
    instruments,
    quantity,
    stopGainType,
    stopLossType,
    segment
  } = config;

  const pipeConfig = [{
    type: 'superTrend',
    name: `superTrend${period}`,
    config: {
      noOfCandles: Number(period),
      candleInterval: interval,
      multiplier,
      valuePaths: ['item0.month1Price'],
    },
  }, {
    type: 'relativeStrengthIndex',
    name: `rsi${period}`,
    config: {
      noOfCandles: Number(periodRsi),
      candleInterval: '1 minutes',
      valuePaths: ['item0.month1Price'],
    },
  },
  current1minuteCandle()
  ];
  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs, expiryHandling, pipeConfigs } = emptyConfig;
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const setOptionConfig = {
    ...optionConfigObj,
    entryType: actiontype,
    optionType,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    strikeIndex: Number(strikeIndex),
    quantity: Number(quantity),
    sortId: 0
  };
  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: `superTrend${period}`,
      operator: comparisonType,
      rightOperand: `${CURRENT_ONE_MINUTE_CANDLE_NAME}.close`,
    }, {
      conditionOperator: 'and',
      leftOperand: `rsi${periodRsi}`,
      operator: comparisonTypeRsi,
      rightOperand: '55',
    }],
    exitSignalConfigs: [
      {
        conditionOperator: '',
        leftOperand: `superTrend${period}`,
        operator: 'crossesBelow',
        rightOperand: `${CURRENT_ONE_MINUTE_CANDLE_NAME}.close`,
      },
      {
        conditionOperator: 'or', leftOperand: `rsi${periodRsi}`, operator: 'crossesBelow', rightOperand: '40',
      }
    ],
    transactionConfigs: [
      { ..._.head(transactionConfigs), optionConfigs: [{ ...optionConfigObj, ...setOptionConfig, }] }
    ]
  }];

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    pipeConfigs: [...pipeConfigs, ...pipeConfig],
    orderConfigs: modifiedOrderConfigs,
    tradingType: 'positional',
    expiryHandling: { ...expiryHandling, exitTime: '15:28' }
  };
};
