import { instrumentsWithWeeklyExpiry } from 'common/utils/optionExpiryCycleHelper';
import { getAlteredEmptyQuantConfig, OPTIONS_EXPIRY } from 'modules/QuantBuilder/config/index';
import { getStopGainPercent, getStopGainPrice } from '../../config';

export const IRON_FLY = 'ironFly';

export const ironFlyDefaultConfig = {
  optionType1: 'CE',
  optionType2: 'PE',
  optionType3: 'CE',
  optionType4: 'PE',
  standardDeviation1: 0,
  standardDeviation2: 0,
  time: '',
  stopLoss1: 0,
  stopLoss2: 0,
  stopLoss3: 0,
  stopLoss4: 0,
  stopGain1: 0,
  stopGain2: 0,
  stopGain3: 0,
  stopGain4: 0,
  days: 1,
  monthWeek: OPTIONS_EXPIRY.monthly,
  actiontype1: 'sell',
  actiontype2: 'sell',
  actiontype3: 'buy',
  actiontype4: 'buy',
  standardDeviation3: 2,
  standardDeviation4: 2,
  quantity1: 1,
  quantity2: 1,
  quantity3: 1,
  quantity4: 1,
  stopLoss1Type: 'percent',
  stopLoss2Type: 'percent',
  stopLoss3Type: 'percent',
  stopLoss4Type: 'percent',
  stopGain1Type: 'percent',
  stopGain2Type: 'percent',
  stopGain3Type: 'percent',
  stopGain4Type: 'percent',
};

export const quantConfigConversionIronFly = (config) => {
  const {
    optionType1, optionType2, optionType3, optionType4, standardDeviation1, standardDeviation2, time,
    stopLoss1, stopLoss2, stopLoss3, stopLoss4, stopGain1, stopGain2, stopGain3, stopGain4,
    instruments, days, monthWeek, actiontype1,
    actiontype2, actiontype3, actiontype4, standardDeviation3, standardDeviation4,
    quantity1, quantity2, quantity3, quantity4, stopLoss1Type, stopLoss2Type, stopLoss3Type,
    stopLoss4Type, stopGain1Type, stopGain2Type, stopGain3Type, stopGain4Type, segment
  } = config;

  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs, expiryHandling } = emptyConfig;
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const optionConfig = [
    {
      entryType: actiontype1,
      optionType: optionType1,
      stopGainPrice: getStopGainPrice(stopGain1Type, stopGain1),
      stopLossPrice: getStopGainPrice(stopLoss1Type, stopLoss1),
      stopGain: getStopGainPercent(stopGain1Type, stopGain1),
      stopLoss: getStopGainPercent(stopLoss1Type, stopLoss1),
      standardDeviation: Number(standardDeviation1),
      quantity: Number(quantity1),
      sortId: 0
    },
    {
      ...optionConfigObj,
      entryType: actiontype2,
      optionType: optionType2,
      stopGainPrice: getStopGainPrice(stopGain2Type, stopGain2),
      stopLossPrice: getStopGainPrice(stopLoss2Type, stopLoss2),
      stopGain: getStopGainPercent(stopGain2Type, stopGain2),
      stopLoss: getStopGainPercent(stopLoss2Type, stopLoss2),
      standardDeviation: Number(standardDeviation2),
      quantity: Number(quantity2),
      sortId: 1
    },
    {
      ...optionConfigObj,
      entryType: actiontype3,
      optionType: optionType3,
      stopGainPrice: getStopGainPrice(stopGain2Type, stopGain3),
      stopLossPrice: getStopGainPrice(stopLoss3Type, stopLoss3),
      stopGain: getStopGainPercent(stopGain3Type, stopGain3),
      stopLoss: getStopGainPercent(stopLoss3Type, stopLoss3),
      standardDeviation: Number(standardDeviation3),
      quantity: Number(quantity3),
      sortId: 2
    },
    {
      ...optionConfigObj,
      entryType: actiontype4,
      optionType: optionType4,
      stopGainPrice: getStopGainPrice(stopGain4Type, stopGain4),
      stopLossPrice: getStopGainPrice(stopLoss4Type, stopLoss4),
      stopGain: getStopGainPercent(stopGain4Type, stopGain4),
      stopLoss: getStopGainPercent(stopLoss4Type, stopLoss4),
      standardDeviation: Number(standardDeviation4),
      quantity: Number(quantity4),
      sortId: 3
    }
  ];

  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: 'daysToExpire',
      operator: 'equalTo',
      rightOperand: `${days}`
    },
    {
      conditionOperator: 'and',
      leftOperand: 'timeOfDay',
      operator: 'equalTo',
      rightOperand: time
    }],
    transactionConfigs: [
      {
        ..._.head(transactionConfigs),
        optionConfigs: optionConfig,
      }
    ]
  }];

  let optionsExpiryCycle = 'monthly';
  if (_.includes(_.flatten(instruments), _.head(instrumentsWithWeeklyExpiry))
    || _.includes(_.flatten(instruments), instrumentsWithWeeklyExpiry[1])) {
    optionsExpiryCycle = monthWeek;
  }

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    orderConfigs: modifiedOrderConfigs,
    optionStrikeCalculationBasedOn: 'standardDeviation',
    optionsExpiryCycle,
    tradingType: 'positional',
    timeBasedSignal: {
      entryTime: '',
      exitTime: ''
    },
    expiryHandling: {
      ...expiryHandling,
      exitTime: '15:28'
    }
  };
};
