import React from 'react';
import PropTypes from 'prop-types';

import Money from 'common/components/MoneyDisplay';
import { transactionPropTypes } from 'common/propTypes';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import moment from 'moment';

const propTypes = {
  transaction: transactionPropTypes.isRequired,
  cumulativeProfit: PropTypes.number,
  isNumerousRuns: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
  granularity: PropTypes.string.isRequired
};
const defaultProps = {
  cumulativeProfit: null
};

const TransactionCollapsed = ({
  transaction, cumulativeProfit, isNumerousRuns, isLive, granularity
}) => {
  const {
    sortId,
    transactionNumber,
    instruments,
    overallProfit,
    isComplete,
    isIlliquidTransaction,
    transactionAndRunId,
    pairedOrders
  } = transaction;
  const renderTime = (isEntry) => {
    let times = _.chain(pairedOrders).map((
      { entryTime, exitTime }
    ) => (isEntry ? entryTime : exitTime)).uniq().sort()
      .compact()
      .value();

    if (!isLive && granularity === 'minute') {
      times = _.map(times, (time) => moment(time, 'DD MMM YYYY HH:mm:ss')
        .format('DD MMM YYYY HH:mm'));
    }
    if (_.isEmpty(times)) return <div className="time text-left">-</div>;
    if (_.size(times) === 1) return <div className="time text-left">{_.first(times)}</div>;

    return <div className="time text-left tx-10">{`${_.first(times)} - ${_.last(times)}`}</div>;
  };

  return (
    <tr className="collapsed" key={sortId}>
      <td className="align-middle">
        <div className="transaction-number">
          {!isNumerousRuns ? transactionNumber : transactionAndRunId}
        </div>
      </td>
      <td className="text-wrap"><InstrumentGroupsSentence instrumentGroups={[instruments]} /></td>
      <td />
      <td colSpan="3" className="entry-wrapper">
        {renderTime(true)}
      </td>
      <td colSpan="3" className="exit-wrapper">
        {renderTime(false)}
      </td>
      <td className="text-right">
        {isComplete && <Money shouldColor>{overallProfit}</Money>}
      </td>
      <td className="align-middle text-success text-right">
        {!isComplete && <div className="incomplete" />}
        {isIlliquidTransaction && <div className="illiquid" />}
        {isComplete && <Money shouldColor>{cumulativeProfit}</Money>}
      </td>
    </tr>
  );
};

TransactionCollapsed.propTypes = propTypes;
TransactionCollapsed.defaultProps = defaultProps;
export default TransactionCollapsed;
