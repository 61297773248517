import React from 'react';
import PropTypes from 'prop-types';
import { getSymbolType } from 'ui/run/RunShow/config';
import { SEGMENT_CONFIG } from 'common/constants/index';

const propTypes = { tradingSymbol: PropTypes.string };
const defaultProps = { tradingSymbol: '' };

const TradingSymbol = ({ tradingSymbol }) => {
  if (!tradingSymbol) return null;
  const symbolType = getSymbolType(tradingSymbol);

  const tradingSymbolConfig = () => {
    switch (symbolType) {
      case 'CE':
      case 'PE':
      case 'FUT':
        const sizeOfTradingSymbol = _.size(tradingSymbol);
        const instrumentEndIndex = tradingSymbol.search(/[0-9]/);
        const instrument = tradingSymbol.substring(0, instrumentEndIndex);
        const expiryDate = tradingSymbol.substring(instrumentEndIndex, instrumentEndIndex + 7,);
        if (symbolType === 'FUT') {
          return {
            strikePrice: '', expiryDate, instrument, segment: SEGMENT_CONFIG.future
          };
        }
        const strikePrice = tradingSymbol.substring(instrumentEndIndex + 7, sizeOfTradingSymbol - 2);

        return {
          strikePrice, expiryDate, instrument, segment: SEGMENT_CONFIG.option
        };
      default:
        return {
          instrument: tradingSymbol, expiryDate: '', strikePrice: '', segment: SEGMENT_CONFIG.equity
        };
    }
  };

  const {
    instrument, expiryDate, strikePrice
  } = tradingSymbolConfig();

  return (
    <>
      <span className="mr-1 font-weight-bold">{instrument}</span>
      {expiryDate && <span className="mr-1 font-weight-normal">{expiryDate}</span>}
      {strikePrice && <span className="mr-1 font-weight-bold">{strikePrice}</span>}
      {symbolType !== 'EQUITY' && <span>{symbolType}</span>}
    </>
  );
};

TradingSymbol.propTypes = propTypes;
TradingSymbol.defaultProps = defaultProps;

export default TradingSymbol;
