import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { getQuantModes, defaultUiBehaviourFlags } from 'v2/modules/withRunForm/configs/index';

const propTypes = {
  isPipeIntent: PropTypes.bool,
  isBookProfitTsl: PropTypes.bool,
};
const defaultProps = {
  isPipeIntent: false,
  isBookProfitTsl: false,
};

const renderQuickAdjustments = () => {
  return (
    <div className="transaction_details-items quick-adjustments">
      Triggers
      <img src="/v2Crown.png" className="v2-crown-image" alt="" />
    </div>
  );
};

const IntentHeaders = ({ isPipeIntent, isBookProfitTsl }) => {
  const uiBehaviourFlags = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiBehaviourFlags')
  );
  const maxInstrumentsInGroup = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.maxInstrumentsInGroup')
  );
  // TODO: Copied from useOpenIntent, DRY it.
  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;
  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);
  // ------------------------------
  const isShowInstrumentIndex = useMemo(() => maxInstrumentsInGroup > 1, [maxInstrumentsInGroup]);

  const {
    isReEntry,
    isReExecute,
    isTrailingStopLoss,
    isWaitAndTrade
  } = uiBehaviourFlags || defaultUiBehaviourFlags;

  const renderRenTryOrReExecuteLabel = useMemo(() => {
    if (isReEntry && isReExecute) {
      return 'Re-Entry/Re-Execute';
    }
    if (isReEntry) {
      return 'Re-Entry';
    }

    return 'Re-Execute';
  }, [isReEntry, isReExecute]);

  const instrumentClassName = classNames('transaction_details-items', {
    instrument: !isShowInstrumentIndex,
    with_advanced_mode: isAdvancedMode,
    pair_instrument: isShowInstrumentIndex,
  });

  return (
    <div className="transaction_details head">
      <div className="transaction_details-items intent-type">Action</div>
      <div className="transaction_details-items segment">Segment</div>
      <div className={instrumentClassName}>Instrument</div>
      <div className="transaction_details-items quantity">Quantity</div>
      <div className="transaction_details-items action_type">Action</div>
      <div className="transaction_details-items stop_loss">Stop Loss</div>
      <div className="transaction_details-items take_profit">Take Profit</div>
      {isTrailingStopLoss && !isPipeIntent
      && (
      <div
        className={
          `transaction_details-items trailing_stop_loss ${isBookProfitTsl ? 'tsl_book_and_linear' : ''}`
        }
      >
        Trailing Stop Loss
      </div>
      )}
      {isWaitAndTrade
        && !isPipeIntent
        && <div className="transaction_details-items wait_and_trade">Wait and Trade</div>}
      {((isReEntry || isReExecute) && !isPipeIntent)
          && (
          <div className="transaction_details-items re_entry_execute">
            {renderRenTryOrReExecuteLabel}
          </div>
          )}
      {renderQuickAdjustments()}
      <div className="transaction_details-items delete" />
    </div>
  );
};

IntentHeaders.propTypes = propTypes;
IntentHeaders.defaultProps = defaultProps;

export default IntentHeaders;
