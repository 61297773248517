import _ from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { outputSchemaPropTypes, pipeConfigPropTypes } from 'common/propTypes';
import { TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { pipeInputSchemaTypes } from 'v2/common/quantConfig/pipeDefinitions/config';
import { SPECIAL_CHARACTER_REGEX } from 'v2/common/constants/index';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import SignalsView from 'v2/modules/withRunForm/components/Signals/SignalsView/index';
import IntentsView from 'v2/modules/withRunForm/components/Intents/IntentsView/index';
import StopConfigView from 'v2/modules/withRunForm/components/Intents/IntentsView/StopConfigView';
import { customDisplayConfig } from '../PipeConfigForm/customDisplayConfig';
import EditOrDeleteButton from './EditOrDeleteButton';
import ExternalSignalView from './ExternalSignalView';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  onDeletePipeConfig: PropTypes.func,
  onEditPipeConfig: PropTypes.func,
  isDependent: PropTypes.bool,
  isViewOnly: PropTypes.bool,
};
const defaultProps = {
  isViewOnly: false,
  onDeletePipeConfig: () => {},
  onEditPipeConfig: () => {},
  isDependent: false
};

const CUSTOM_LABELS = {
  valuePaths: 'Fields',
};

const PipeConfigView = ({
  pipeConfig, outputSchema, onDeletePipeConfig, onEditPipeConfig, isDependent, isViewOnly
}) => {
  const namePairLabel = _.reduce(outputSchema, (acc, schema) => {
    acc[schema.name] = TypeheadSchemaUtility.getOption(schema)?.label || schema.label;
    return acc;
  }, {});

  const {
    type, name, config, chartConfig
  } = pipeConfig;
  const inputSchema = useMemo(
    () => PipeDefinitionUtility.getPipeInputSchemaByType(type),
    [type]
  );

  const alteredChartConfig = _.chain(chartConfig)
    .omit(['boxUnitSize', 'boxPercentage', 'isContinuousRollup'])
    .reduce((acc, value, key) => {
      if (key === 'type') acc.chartType = value;
      else acc[key] = value;

      return acc;
    }, {})
    .value();

  const renderLabelAndValue = (label, value, fieldType) => {
    if (!value && !_.isBoolean(value)) return null;

    let renderValue = '';
    if (_.includes([pipeInputSchemaTypes.signal], fieldType)) {
      renderValue = <SignalsView signals={value} outputSchema={outputSchema} isViewOnly parentClassName="" />;
    } else if (_.includes([pipeInputSchemaTypes.intents], fieldType)) {
      renderValue = <IntentsView intents={value} outputSchema={outputSchema} />;
    } else if (_.includes([pipeInputSchemaTypes.externalSignalId], fieldType)) {
      renderValue = <ExternalSignalView pipeConfig={pipeConfig} />;
    } else if (_.includes([pipeInputSchemaTypes.stopLoss], fieldType)) {
      renderValue = <StopConfigView config={value} type="StopLoss" />;
    } else if (_.isObject(value)) {
      renderValue = JSON.stringify(value);
    } else if (_.isBoolean(value)) {
      renderValue = _.startCase(`${value}`);
    } else {
      renderValue = ReactHtmlParser(value);
    }

    return (
      <div className="label d-flex align-items-center mr-1" key={_.uniqueId('label_')}>
        <span className="tx-12">
          {_.startCase(_.get(CUSTOM_LABELS, label, label))}
          { ' :'}
        </span>
        <span className="font-weight-medium tx-12 ml-1">
          {renderValue}
        </span>
      </div>
    );
  };

  const getDefaultLabel = (value) => {
    if (value
        && !_.isObject(value)
        && Number.isNaN(Number(value))
        && !(SPECIAL_CHARACTER_REGEX.test(value))
    ) {
      return _.startCase(value);
    }

    return value;
  };

  const renderConfig = (value, key) => {
    const label = (key === 'valuePaths')
      ? _.map(value, (val) => _.get(namePairLabel, val, val ? _.startCase(val) : val)).join(', ')
      : _.get(namePairLabel, value, getDefaultLabel(value));
    const inputSchemaConfig = _.find(inputSchema, { name: key }) || {};
    const fieldType = _.get(inputSchemaConfig, 'type', key);
    const isDisplay = _.get(
      inputSchemaConfig,
      'isDisplay',
      _.get(customDisplayConfig(pipeConfig), key, true)
    );

    if (!isDisplay) return null;

    return renderLabelAndValue(key, label, fieldType);
  };

  return (
    <div className="pipe_parent__view d-flex align-items-center justify-content-between mb-2">
      <div className="d-flex align-items-center gap-3 flex-wrap">
        <span className="font-weight-medium tx-14 mr-1 label-info text-nowrap">{_.startCase(type)}</span>
        {renderLabelAndValue('name', _.startCase(name))}
        {Object.entries(_.omit(config, ['script', 'snippetId', 'outputJsonSchema', 'token']))
          .map(([key, value]) => renderConfig(value, key))}
        {Object.entries(alteredChartConfig).map(([key, value]) => renderConfig(value, key))}
      </div>
      {!isViewOnly && (
        <EditOrDeleteButton
          onDeletePipeConfig={onDeletePipeConfig}
          onEditPipeConfig={onEditPipeConfig}
          isDependent={isDependent}
        />
      )}
    </div>
  );
};

PipeConfigView.propTypes = propTypes;
PipeConfigView.defaultProps = defaultProps;

export default PipeConfigView;
